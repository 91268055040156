.payment-sidebar {
    top: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    position: fixed;
    width: 55%;
    background-color: var(--background-color);
    color: var(--text-color);
    overflow: auto;
    z-index: 99;
    padding: 20px;
}

.pending-btn {
    height: 24px;
    width: 59px;
    border-radius: 10px;
    border: none;
    margin-left: 23px;
    background-color: coral;
    color: white;
}

.payment-date-time {
    float: right;
}

.bank-transfer {
    margin-left: 7%;
}

.amount-input {
    background-color: #F0F1FF;
    border: 1px solid #C0C2DE;
    height: 28px;
    outline: none;
}

.verify-btn,
.request-btn {
    height: 30px;
    width: 100px;
    border-radius: 30px;
    border: none;
    background-color: #C0C2DE;
    font-size: 12px !important;
}

.QR-image {
    height: 42%;
    width: 20%;
}

.small-input-button {
    height: 29px;
    width: 220px;
    color: var(--text-color);
    border: 1px solid#C0C2DE;
    background-color: var(--background-color);
    margin: 3px;
    font-size: 12px;
    outline: none;
    padding-left: 10px;
}

.small-input-date1 {
    height: 29px;
    width: 170px;
    color: var(--text-color);
    border: 1px solid #C0C2DE;
    background-color: var(--background-color);
    margin-top: 3px;
    font-size: 12px;
    outline: none;
    padding-left: 10px;
    cursor: pointer;
    margin-left: 35px;
}


.small-input-btn {
    height: 29px;
    width: 170px;
    color: var(--text-color);
    border: 1px solid#C0C2DE;
    background-color: var(--background-color);
    margin: 3px;
    font-size: 12px;
    outline: none;
    margin-left: 12px;
    padding-left: 10px;
}

.small-input-btn1 {
    height: 29px;
    width: 170px;
    color: var(--text-color);
    border: 1px solid#C0C2DE;
    background-color: var(--background-color);
    font-size: 12px;
    outline: none;
    padding-left: 10px;
    margin-left: 35px;
}

.small-input-btns {
    height: 29px;
    width: 170px;
    color: var(--text-color);
    border: 1px solid#C0C2DE;
    background-color: var(--background-color);
    margin: 3px;
    font-size: 12px;
    outline: none;
    padding-left: 10px;
}

.view-img {
    height: 30px;
    width: 95px;
    border-radius: 30px;
    border: none;
    background-color: #C0C2DE;
    font-size: 12px;
    margin-left: 2%;
}

.upload-doc {
    display: none;
}

.small-input-btn-qr {
    height: 29px;
    width: 170px;
    color: var(--text-color);
    border: 1px solid#C0C2DE;
    background-color: #F0F1FF;
    margin: 3px;
    font-size: 12px;
    outline: none;
    padding-left: 10px;
}

.close-btn {
    margin-top: 20px;
    font-size: 12px;
    margin-left: 10px;
}

.payupi {
    position: absolute;
    top: 202px;
    margin-left: -287px;
}

.amountInput {
    height: 29px;
    width: 170px;
    color: var(--text-color);
    border: 1px solid#C0C2DE;
    background-color: #F0F1FF;
    margin: 3px;
    font-size: 14px;
    outline: none;
    margin-left: 3px;
    margin-top: 7px;
}

.addAmount {
    position: fixed;
    margin-left: 21px;
    margin-top: 6px;
    height: 30px;
    width: 85px;
    border-radius: 30px;
    border: none;
    background-color: #C0C2DE;
}

.Create-Order {
    margin-top: 20px;
    height: 30px;
    width: 95px;
    border-radius: 30px;
    border: none;
    background-color: #C0C2DE;
    font-size: 12px;
}

.invalid-feedback {
    margin-top: 5px;
    font-size: 13px;
    color: red;
}

.popup-message p {
    color: green;
    margin-left: 15px;
}

.verify-button {
    width: 92px;
    background-color: #C0C2DE;
    border-radius: 22px;
    border: none;
    height: 30px;
    margin-left: 16px;
    font-size: 12px;
    margin-bottom: 10px;
}

.verify-button-pay {
    width: 100px;
    background-color: #C0C2DE;
    border-radius: 22px;
    border: none;
    position: relative;
    bottom: 13px;
    height: 30px;
    margin-left: -40px;
    font-size: 12px;
}

.request {
    height: 33px;
    width: 200px;
    border-radius: 30px;
    border: none;
    background-color: #dec0cb;
    margin-left: 348px;
    position: relative;
    top: -90px;
}

.add-customer-inputs1 {
    background-color: transparent;
    border: 1px solid #C0C2DE;
    opacity: 1;
    height: 31px;
    outline: none;
    position: relative;
    bottom: 19px;
    top: -11px;
    left: 2px;
}

.partially-paid-btn {
    height: 30px;
    width: 92px;
    border-radius: 10px;
    border: none;
    margin-left: 20px;
    background-color: orangered;
    color: white;
}

.unpaid-btn {
    height: 29px;
    width: 63px;
    border-radius: 10px;
    border: none;
    margin-left: 22px;
    background-color: red;
    color: white;
}

.pay-status {
    padding-left: 10px;
}

input[type=radio] {
    box-sizing: border-box;
    padding: 0;
    cursor: pointer;
}

.view-button {
    height: 30px;
    width: 55px;
    border-radius: 30px;
    border: none;
    background-color: #C0C2DE;
    font-size: 12px;
}

.view-button-3 {
    height: 33px;
    width: 90px;
    border-radius: 30px;
    border: none;
    background-color: #C0C2DE;
    font-size: 12px;
}