.no-order {
    background: transparent !important;
}

.service_os {
    height: 37vh !important;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
}

.allOrder-loader {
    position: relative;
    top: 130px;
    left: 80vh;
}

.all-order-statistics {
    margin-top: 5px;
    height: 68px;
    width: 140px !important;
    background: var(--card-bg) 0% 0% no-repeat padding-box;
    color: var(--text-color);
    box-shadow: 0px 1px 8px 4px #dcdded;
    border-radius: 5px;
    opacity: 1;
    background-color: var(--background-color);
    border: none;
    font-size: 14px;
}




