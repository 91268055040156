.generate-qr {
    height: 30px;
    width: 113px;
    background-color: #C0C2DE;
    border: none;
    border-radius: 20px;
    margin-left: 10px;
    font-size: 13px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.update-payment-sidebar {
    top: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    position: fixed;
    width: 57%;
    background-color: var(--background-color);
    color: var(--text-color);
    overflow: auto;
    z-index: 99;
    padding: 20px;
}

.payment-popup {
    position: fixed;
    background: #a5141410;
    width: 50%;
    height: 100vh;
    top: 0;
    right: 0;
}

.payment-box {
    position: relative;
    width: 22%;
    min-height: 160px;
    background: #fff;
    border-radius: 4px;
    margin: 275px auto;
    padding: 20px;
    overflow: auto;
}

.small-input-date {
    height: 29px;
    width: 170px;
    color: var(--text-color);
    border: 1px solid #C0C2DE;
    background-color: var(--background-color);
    margin: 3px;
    font-size: 12px;
    outline: none;
    padding-left: 10px;
    cursor: pointer;
}

.btn-Credit-paid {
    width: 82px;
    height: 26px;
    background: #964B00 0% 0% no-repeat padding-box;
    border-radius: 14px;
    opacity: 1;
    color: white;
    border: none;
    font-size: 12px;
}