.warehouse-sidebar {
    top: 0%;
    right: 0;
    bottom: 0;
    height: 100vh;
    z-index: 999;
    position: fixed;
    width: 30%;
    background-color: var(--background-color);
    overflow: hidden;
}

.warehouse-manager {
    width: 102%;
    border-radius: 5px;
}

.scrollbar-warehouse {
    height: calc(97vh - 160px);
    overflow-x: hidden;
    overflow-y: auto;
}

.my-toast {
    background-color: #51a351;
}

.custom-toast {
    z-index: 1000;
}

.ToastContainer {

    .Toastify__toast-container {
        z-index: 10000;
    }
}

.warehouse-select {
    width: 45%;
    margin-left: 14px;
}

.ware-name {
    font-size: 18px;
    color: var(--text-color);
}

.warehouse-button {
    border: none;
    background: #C0C2DE;
    color: black;
    height: 34px;
    border-radius: 18px;
    width: 25%;
    margin-left: 3%;
    font-size: 13px;
}

.custom-progressess {
    background-color: #51a351 !important;
}

.cancel-ware {
    color: var(--text-color);
    height: 34px;
    border: 1px solid #C0C2DE;
    background: transparent;
    border-radius: 22px;
    width: 25%;
    font-size: 13px;
}

.ware-text {
    font-size: 14px;
    color: var(--text-color);
    padding-left: 3px;
}

.hr-wareside {
    margin-top: 2rem;
    margin-bottom: 1rem;
    width: 91%;
    margin-left: 15px;
    background-color: var(--icon-color);
}

.ware-inp {
    line-height: 0px;
}

.warehouse-text {
    font-size: 16px;
}

.warehouse-line {
    font-size: 14px;
}

.ware-info {
    font-size: 14px;
}

.prod-inputs:focus {
    outline: none;
}

.feature-btn {
    background-color: gray;
    border: none;
    height: 2rem;
    border-radius: 3px;
    width: 95px;
    margin-top: 10%;
    color: #000000;
}

.Manager-input {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #C0C2DE;
    color: var(--text-color);
    font-size: 14px;
    width: 85%;
    font-size: 14px;
    background: transparent;
}

.manager-select {
    border: 1px solid #847C7C;
    opacity: 1;
    background: transparent;
    width: 85%;
    height: 38px;
    font-size: 14px;
    padding-left: 3%;
}

.avatar-image {
    margin-left: 110%;
}

@media (min-width:768px) and (max-width:992px) {
    .avatar-image {
        margin-left: 0px;
    }
}

@media (min-width:1120px) and (max-width:1290px) {
    .avatar-image {
        margin-left: 50px;
    }
}

@media (min-width:992px) and (max-width:1120px) {
    .avatar-image {
        margin-left: 55px;
    }
}