.dropdown-style {
  text-align: start !important;
}

.dropdown-menu {
  border: none;
  position: absolute;
  right: 45px;
  background-color: #F0F1FF;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 6px;
}

.service {
  height: 58vh !important;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  right: 10px;
}

@media (min-width: 1200px) and (max-width: 1365px) {}


.pagination5 {
  display: flex;
  list-style: none;
  padding: 0;
  position: fixed;
  margin-left: 360px;
  width: 120px;
  bottom: 10px;
  border-radius: 4px;
  background-color: #F5F5FC;
  // border: 1px solid #B4B8ED;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.pagination5 li {
  margin: 0 5px;
  display: inline-block;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 4px;
}

.pagination5 li.active {
  color: black;
  font-weight: 700;
  border-radius: 4px;
}

.pagination5 .previous {
  padding: 5px 10px;
  border-radius: 3px;
  margin-right: 5px;
  cursor: pointer;
}

.pagination5 .previous a {
  color: limegreen;
  outline: none;
  text-decoration: none;
  background-color: transparent;
}

.pagination5 .next {
  color: red;
  padding: 5px 10px;
  border-radius: 3px;
  margin-left: 5px;
  cursor: pointer;
}

.pagination5 .next a {
  color: limegreen;
  text-decoration: none;
  outline: none;
  background-color: transparent;
}

.pagination5 .previous.disabled a,
.pagination5 .next.disabled a {
  color: #a0dca0;
  cursor: not-allowed;
}