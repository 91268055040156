.service-request-title {
  margin-top: 3px;
  font-size: 18px;
  font-family: 800;
  color: var(--text-color) !important;
}

.cardstyle {
  margin-top: -15px;
  margin: 5px;
  width: 120px;
  height: 71px;
  margin-left: 6px;
  background: var(--card-bg) 0% 0% no-repeat padding-box;
  color: var(--text-color);
  box-shadow: var(--secondary-background) !important;
  border-radius: 5px;
  opacity: 1;
  background-color: var(--background-color);
  border: none;
}

.card-box {
  margin-top: 10px;
  width: 98px;
  height: 88px;
  margin-left: 11px;
  top: 5px;
  background-color: var(--background-color);
  border-radius: 3px;
  opacity: 1;
}

.card-name {
  color: #766b6b;
  font-weight: 100;
  margin: 2px;
  color: var(--text-color);
  margin-top: 8px;
  font-size: 14px;
}

.cardname {
  cursor: pointer;
}

.start-input {
  color: var(--text-color);
  height: 34px;
  border: 1px solid #C0C2DE;
  font-size: 14px;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 7px;
  width: 85px;
}

.heading_flex {
  display: flex;
  justify-content: space-between;
}

.date_flex {
  display: flex;
  z-index: 999;
}

.btn-data {
  border: none;
  background-color: #C0C2DE;
  border-radius: 15px;
  width: 80px;
  font-weight: lighter;
  font-size: 12px;
  height: 30px;
  margin-left: 25px;
}

.card-num {
  font-weight: bold;
  font-size: 100%;
  margin-top: -4px;
}

.card-num {
  font-weight: bold;
  font-size: 100%;
}

.font13 {
  font-size: 14px;
}

.search-input_service-box {
  outline: none;
  color:var(--text-color);
  width: 200px;
  border: none;
  background: transparent;
  border: 2px solid #C9C9C9 !important;
  border-radius: 20px;
  height: 35px;
  padding-left: 25px;
}

.search-input_service-box::placeholder {
  max-width: 358px !important;
  font-size: 12px;
}

.search-icon-order {
  position: absolute;
  top: 16px;
  transform: translatex(80%);
  font-size: 14px;
  color: #999;
}

.payment-status-style {
  font-weight: bold !important;
  font-size: 14px;
}

.primary-button-style {
  background-color: red !important;
}

.btn_primary {
  border: 0px solid #c0c2de;
  border-radius: 20px;
  width: 100px;
  font-weight: 500;
  background-color: #c0c2de;
  cursor: pointer;
  align-items: center;
  height: 33px;
}

.btn_primary_1 {
  border: 0px solid #c0c2de;
  border-radius: 20px;
  width: 100px;
  font-weight: 300;
  background-color: #c0c2de;
  cursor: pointer;
  align-items: center;
  height: 33px;
}

.table-loader {
  position: absolute;
  left: 45%;
  bottom: 170px;
}

.selectDepartment {
  margin-top: 5px;
}

.btn-position {
  position: absolute;
  right: 25px;
}

.data {
  background-color: transparent;
}

.textloader {
  position: absolute;
  margin-left: 420px;
  margin-top: 90px;
}

.select-year {
  color: var(--text-color);
  background-color: var(--secondary-background-color);
}

.select-type {
  color: var(--text-color);
  background-color: var(--secondary-background-color);
}

.select-department {
  color: var(--text-color);
  background-color: var(--secondary-background-color);
}

input.search-input-order.border-bottom.font14 {
  outline: none;
}

.search-input-service {
  background-color: red !important;
}

.search-bar-container {
  display: flex;
  align-items: center;
}

.main-padding {
  padding-left: 40px;
  padding-top: 19px;
}

.select-dep-cust{
  width: 125px;
}

.table-scroll-sr-order {
  height: calc(100vh - 370px);
  overflow-x: hidden;
  overflow-y: auto;
  margin-right: 0;
  padding-right: 0;
  scrollbar-color: transparent transparent;
}

.data-image {
  background: transparent !important;
  text-align: center;
  position: fixed;
  top: 58%;
  left: 50%;
}

.d_flex {
  display: flex;
  justify-content: space-between;
}

.flex_dropdown {
  display: flex;
  margin-top: 13px;
}

.delete-addnew-btn1 {
  position: relative;
  margin-top: -9px;
  right: 22px;
}

@media (min-width: 1200px) and (max-width: 1365px) {
  .search-input-order {
    width: 29vh !important;
  }

  .btn_primary {
    width: 80px;
    height: 30px;
  }
}

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
}