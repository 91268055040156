.table-input {
    width: 40px;
    background-color: transparent;
    text-align: center;
    border: 1px solid gray;
    border-radius: 5px;
}

.footer-btn {
    margin-top: 2px;
    margin-right: 41%;
}

.contact-person {
    font-size: 16px;
    font-weight: 700 !important;
}

.input-error {
    border: none;
    color: red !important;
    font-size: 14px;
}

.head-theme {
    color: var(--text-color);
}

.pur-sub {
    position: relative;
    bottom: 10px;
}

.select-status3 {
    font-size: 14px;
    background-color: transparent;
    height: 34px;
    width: 270px;
    border-radius: 5px;
    border: 1px solid #C0C2DE;
    position: relative;
    cursor: pointer;
    outline: none;
    padding-left: 10px;
    color: var(--text-color);
}

.footer-btn-container {
    justify-content: flex-start;
}
.print-button-page{
    border-radius: 15px;
    font-size: 12px;
    width: 100px;
    height: 30px;
    border: none;
    outline: none;
    color: black;
}