.feature-saves {
    background-color: #c0c2de;
    border: none;
    font-size: 14px;
    height: 35px;
    outline: none;
    color: var(--text-color);
}

.change-button {
    width: 80px;
    height: 30px;
    background: #000000 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    color: white;
    font-size: 12px;
    border: black;
    border: none;
    margin-left: 60px
}

.add-button {
    width: 80px;
    height: 30px;
    background: #000000 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    color: white;
    font-size: 12px;
    border: black;
    border: none;
    margin-left: 16%;
}

.feature-preview {
    width: 6.5rem;
    height: 30px;
    background: #CCCCDB 0% 0% no-repeat padding-box;
    border-radius: 7px;
    opacity: 1;
    border: none;
    font-weight: 500;
}

.feature-remove-button {
    width: 84%;
    height: 30px;
    background: #CCCCDB 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    border: none;
    font-weight: 500;
    margin-top: 12px;
    margin-left: 3%;
    font-size: 12px;
}

.adds-button {
    height: 30px;
    background-color: #c0c2de;
    border: 1px solid gray;
    color: white;
    border: none;
    width: 11%;
    font-size: 12px;
    font-weight: 600;
    margin-left: 2%;
    border-radius: 5px;
}

.buy-button-1 {
    background-color: #CCCCDB;
    font-size: 12px;
    margin-top: 10px;
    padding: 12px;
    border-radius: 5px;
    border: none;
    width: 50%;
    color: black;
}

.filter-button {
    background-color: #2F2F2F;
    color: white;
    font-size: 12px;
    border-radius: 5px;
    padding: 5px;
    width: 70px;
    border: #2F2F2F;
}

.minteral-but-1 {
    background-color: #2F2F2F;
    color: white;
    font-size: 12px;
    border-radius: 5px;
    padding: 5px;
    width: 77px;
    border: #2F2F2F;
}


.btn-action {
    margin-left: 22px;
    width: 90px;
    height: 30px;
    background: #000000 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    color: white;
    font-size: 12px;
    border: black;
}

.view-order-button {
    width: 90px;
    height: 30px;
    background: #000000 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    color: white;
    font-size: 11px;
    border: black;
    margin-left: 10px;
}

.plus-icons {
    font-size: 10px;
}

.pencil-change {
    font-size: 9px;
}

@media (min-width:320px) and (max-width:600px) {
    .buy-button-1 {
        width: 65%;
    }

    .change-button {
        width: 70px;
        margin-left: 0px;
        padding-left: 3px;
    }

    .add-button {
        width: 70px;
        margin-left: 0px;
        padding-left: 3px;
    }
}

@media(min-width:600px)and (max-width:768px) {
    .feature-preview {
        font-size: 12px;
        width: 92%;
    }

    .feature-remove-button {
        width: 75%;
    }

    .change-button {
        width: 70px;
        margin-left: 0px;
        padding-left: 10px;
    }

    .add-button {
        margin-left: -30px;
    }
}

@media (min-width:1100px) and (max-width:1213px) {
    .view-order-button {
        width: 80px;
        height: 25px;
        margin-left: 10px;
    }

    .change-button {
        width: 74px;
        height: 30px;
        margin-left: 60px;
        font-size: 10px;
    }

    .add-button {
        width: 70px;
        height: 28px;
        font-size: 10px;
    }
}

@media (min-width:1213px) and (max-width:1400px) {
    .view-order-button {
        width: 80px;
        height: 30px;

    }
}