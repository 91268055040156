.cust-profile-sidebar {
    top: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    float: right;
    z-index: 999;
    position: fixed;
    width: 30%;
    background-color: var(--background-color);
    overflow: hidden;
    padding-right: 0;
}

.text-color2 {
    color: var(--text-color);
}

.payment-input {
    background-color: transparent;
    border: 1px solid #C0C2DE;
    padding: 5px;
    color: var(--text-color);
    width: 100%;
    border-radius: 5px;
}
.error-border {
    border: 1px solid red;
  }
.edit-pencil-icon{
    cursor: pointer;
}
.save-info {
    border: none;
    background: #C0C2DE;
    color: black;
    height: 34px;
    border-radius: 18px;
    width: 25%;
}

.cancel-info{
    color: var(--text-color);
    height: 34px;
    border: 1px solid #C0C2DE;
    width: 74px;
    background: transparent;
    border-radius: 22px;
    width: 25%;
}
.err-alert{
color: red;
}
.payment-btns{
    position: relative;
    bottom: 20px;
}