.popup-box {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  top: 0;
  left: 0;
  background: rgb(0 0 0 / 32%);
}

.popup-box-inventory {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  top: 0;
  left: 0;
  background: rgb(0 0 0 / 7%);
}


.popup-box-warehouse {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  top: 0;
  left: 0;
  background: rgb(0 0 0 / 7%);
}

.popup-box-category {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  top: 0;
  left: 0;
  background: rgb(0 0 0 / 9%);
}

.error-border {
  border: 1px solid red;
}

.cust-box {
  position: relative;
  width: 25%;
  font-size: 16px;
  min-height: 140px;
  background-color: #F0F1FF;
  border-radius: 5px;
  margin: 275px auto;
  padding: 18px;
  padding-left: 30px;
  overflow: auto;
  margin-left: 25%;
  bottom: 70px;
  transform: translate(145px, 10px);
}

.btn-close {
  cursor: pointer;
  position: fixed;
  right: calc(40% - 38px);
  top: 25px;
  border-radius: 50%;
  color: #fff;
  background-color: rgb(12, 12, 12);
  border: 1px solid #999;
  font-size: 14px;
}

.ware-delete {
  cursor: pointer;
  height: 30px;
  width: 50px;
  color: #ffffff;
  background-color: #000000;
  border: none;
  transform: translate(40px, 10px);
  margin-left: 30px;
}

.ware-nodelete {
  cursor: pointer;
  height: 30px;
  width: 50px;
  transform: translate(40px, 10px);
  margin-left: 14px;
  color: #333;
  border: 1px solid gray;
  background-color: transparent;
}

.text-delete {
  padding-top: 5px;
  padding-left: 6px;
  color: #333;
}