.dropdown-menu1 {
    border: none;
    position: absolute;
    right: 45px;
    background-color: #F0F1FF;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 6px;
    width: auto;
}

.view-quotation-style {
    border: none;
    background-color: #c0c2de;
    border-radius: 15px;
    width: 55px;
}

.left-content {
    flex: 1;
}

.right-content {
    display: flex;
    align-items: center;
}
.accept-btn{
    margin-left: 50px;
    height: 30px;
    width: 50%;
    border-radius: 20px;
    background-color: #c0c2de;
    border: 1px solid #C0C2DE;
}

.cancel-qu{
    width: 100px;
    border: 1px solid #C0C2DE;
    background-color: transparent;
    border-radius: 22px;
    height: 30px;
    font-size: 14px;
    color: var(--text-color);
}