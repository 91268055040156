.Sto-sidebar {
    position: fixed;
    overflow-x: hidden;
    z-index: 999;
    transition: margin-right 0.3s ease;
    top: 0;
    right: 0;
    width: 55%;
    height: 100%;
    background-color: var(--background-color);
    overflow-y: auto;
}

.quantity-add-sto {
    background-color: #c2c2e8;
    color: black;
    width: 33%;
    height: 34px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    margin-left: 100px;
}

.stock-cancel {
    margin-left: 1%;
    padding: 5px;
    width: 14%;
    height: 31px;
    margin-top: 7%;
    background-color: transparent;
    color: var(--text-color);
    border: 1px solid #727272;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
}

.ware-color {
    color: var(--text-color);
    height: 38px;
}

.stock-save {
    padding: 5px;
    width: 14%;
    background-color: #c2c2e8;
    color: black;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
}

.delivery_date {
    border: 1px solid rgb(212, 210, 210);
    border-radius: 5px;
    height: 38px;
    width: 275px;
    padding-left: 8px;
    background-color: transparent;
}

.text-orange1 {
    color: orange;
}

.select-stock2 {
    font-size: 14px;
    background-color: transparent;
    height: 34px;
    width: 302px;
    border-radius: 5px;
    border: 1px solid #C0C2DE;
    position: relative;
    cursor: pointer;
    outline: none;
    padding-left: 10px;
    color: var(--text-color);
}

.sto-type {
    font-size: 14px;
    background-color: transparent;
    height: 36px;
    width: 260px;
    border-radius: 5px;
    border: 1px solid #C0C2DE;
    position: relative;
    cursor: pointer;
    outline: none;
    padding-left: 10px;
    color: var(--text-color);
}

.STO-date {
    background-color: transparent;
    font-size: 16px;
    border: 1px solid #C0C2DE;
    border-radius: 5px;
    height: 38px;
    width: 260px;
    padding-left: 10px;
    color: var(--text-color);
}

.select-dat1 {
    font-size: 14px;
    background-color: transparent;
    height: 34px;
    width: 271px;
    border-radius: 5px;
    border: 1px solid #C0C2DE;
    position: relative;
    cursor: pointer;
    outline: none;
    padding-left: 10px;
    color: var(--text-color);
}

.sto_challan {
    padding: 5px;
    width: 20%;
    height: 34px;
    background-color: #c2c2e8;
    color: var(--text-color);
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
}
.sto-update-delivery{
    padding: 5px;
    width: 20%;
    height: 34px;
    background-color: #c2c2e8;
    color: black;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
}

.sto_challan1 {
    padding: 5px;
    width: 20%;
    height: 31px;
    background-color: #c2c2e8;
    color: black;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
}

.type-head_sto {
    width: 301px;
    border-radius: 5px;
    height: 38px;
    background-color: transparent;
    color: var(--text-color);
}

.input-sto {
    border-radius: 5px;
    width: 110%;
}

.sto_update-btn {
    margin-top: 8px;
}

.select-sto_inpt {
    font-size: 14px;
    background-color: transparent;
    height: 34px;
    width: 96%;
    border: 1px solid #C0C2DE;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    outline: none;
    padding-left: 10px;
    color:var(--text-color);
}