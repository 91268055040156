.card-skeleton-loader {
    width: 100%;
    height: 100px;
    background-color: #f0f0f0;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px;
  }
  
  .skeleton-title {
    width: 60%;
    height: 20px;
    background-color: #C0C2DE;
    margin-bottom: 10px;
    border-radius: 3px;
  }
  
  .skeleton-text {
    width: 80%;
    height: 15px;
    background-color: #C0C2DE;
    border-radius: 3px;
  }
  