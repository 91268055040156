.add-customer-sidebar {
    top: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    position: fixed;
    width: 50%;
    z-index: 999;
    color: var(--text-color) !important;
    background-color: var(--background-color) !important;
}

.sidebar-wrapper-customer {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 68vh;
}

.viewDocument {
    border: none;
    cursor: pointer;
    height: 29px;
    width: 55px;
    border-radius: 15px;
    background-color: #c0c2de;
}

.add-customer-title {
    position: relative;
    bottom: 6px
}

.set-proper-title {
    position: relative;
    right: 3px;
}

.uploadDocuments {
    background-color: #c0c2de;
    border: none;
    border-radius: 22px;
    font-size: 14px;
    height: 28px;
    padding-top: 2px;
    width: 111px;
    margin-top: 6px;
    outline: none;
}

.set-upi-input {
    position: relative;
    bottom: 10%;
    right: 7px;
}

.set-proper-UPI {
    position: relative;
    bottom: 3%;
    right: 3px
}

.GST {
    margin-left: 60PX;
    margin-top: -20PX;
}

.GSTTreatment {
    margin-top: -9px;
    margin-left: 46px;
}

.add-customer-inputs {
    background-color: transparent;
    border: 1px solid #C0C2DE;
    opacity: 1;
    height: 35px;
    outline: none;
    border-radius: 5px;
    color: var(--text-color) !important;
    width: 100%;
    background-color: var(--background-color) !important;
}

.add-upi-inputs {
    background-color: transparent;
    border: 1px solid #C0C2DE;
    opacity: 1;
    height: 35px;
    outline: none;
    width: 83%;
    border-radius: 5px;
}

.dropdown-item {
    color: var(--text-color);
}

.add-customer-verify-btn {
    width: 101px;
    background-color: #C0C2DE;
    border-radius: 22px;
    border: none;
    height: 30px;
    position: relative;
    right: 12%;
    bottom: 7px;
    top: 10px;
    outline: none;
}

.add-customer-inputs-upi {
    background-color: transparent;
    border: 1px solid #C0C2DE;
    opacity: 1;
    height: 35px;
    outline: none;
    width: 85%;
    border-radius: 5px;
    color: #333;
}

.radio_button {
    font-size: 14px;
    color: var(--text-color) !important;
}

.hr-line-address {
    position: relative;
    bottom: 15px;
    left: -5px;
    width: 108%;
}

.hr-line-address1 {
    left: 18px;
    width: 108%;
}

.select_state {
    width: 170px;
}

.verified-user-img {
    height: 20px;
}

.tag-addition {
    justify-content: space-between;
}

.save-customer-btn {
    width: 110px;
    background-color: #C0C2DE !important;
    border-radius: 22px;
    border: none;
    height: 30px;
    outline: none;
    margin-left: 20px
}

.mobile_label {
    margin-bottom: -10px;
}

.radio_btn_business_individual {
    margin-bottom: 10px;
    color: #333;
}

.Document {
    margin-top: -1px;
}

.cancel-btn {
    width: 100px;
    border: 1px solid #C0C2DE;
    border-radius: 22px;
    height: 30px;
    font-size: 14px;
    color: var(--text-color) !important;
}

.currency_input {
    width: 109%;
}

.star_color {
    color: #ff0000bf;
}

.close-icon-xmark {
    top: 3%;
    font-size: 16px;
    font-size: 18px;
    cursor: pointer;
    left: 222vh;
    position: fixed;
    color: var(--text-color);
}

.is-invalid {
    border: 1px solid red;
}

.is-invalid-2 {
    border: 1px solid red;
}

.error-message {
    color: red;
    font-size: 13px;
}

.orange-color2 {
    height: 20px;
    color: #f28e47;
    font-weight: 1000;
}

.orange-color1 {
    height: 20px;
    padding-left: 37px;
    letter-spacing: 0px;
    color: #f28e47;
    font-weight: 1000;
    opacity: 1;
}

.srhr1 {
    border: 1px solid #C0C2DE;
    width: 100%;
    position: relative;
    left: 3%;
}

.srhr2 {
    width: 100%;
    position: relative;
    bottom: 6%;
    left: 2%;
}

.popup-message p {
    color: green;
    margin-left: 1px;
}

.panCard_input {
    width: 108%;
}

.address_checkbox {
    position: relative;
    top: 2px;
}

.set-tax {
    padding-left: 18%;
}

.select-Payment-Terms {
    font-size: 14px;
    background-color: #F0F1FF;
    height: 39px;
    width: 248px;
    border: 1px solid #C0C2DE;
    position: relative;
    cursor: pointer;
    outline: none;
    margin-top: 3px;
    border-radius: 6px;
}

.add-address-cursor {
    color: var(--text-color) !important;
    background-color: var(--background-color) !important;
    margin-right: 58vh;
}

.theadStyle {
    position: sticky;
    z-index: 1;
    top: 0;
}

.form-control {
    color: var(--text-color) !important;
    // background-color: var(--background-color) !important;
    background: transparent;
}

.checkbox-cursor {
    cursor: pointer;
}

.select-status {
    border-radius: 5px;
    color: var(--text-color) !important;
    background-color: var(--background-color) !important;
}

.set-row {
    position: relative;
    right: 7px;
}

.cust-radio {
    margin-bottom: 11px;
    color: black !important;
}

.add-customer-title-1 {
    position: relative;
    right: 6px;
    margin-top: 5px;
}

.add-customer-title-2 {
    position: relative;
    right: 6px;
    bottom: 7px;
}

.set-inputs {
    position: relative;
    right: 6px;
    top: 10px;
}

.save-customer-container {
    position: fixed;
    bottom: 15px;
}

.radio-btn-business-individual {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .save-customer-container {
        justify-content: flex-start;

    }
}


@media (min-width: 1200px) and (max-width: 1365px) {
    .save-customer-container {
        justify-content: flex-start;

    }

    .add-customer-verify-btn {
        position: relative;
        right: 10%;
    }

    .sr-hr {
        width: 109%;
        // color: #F0F1FF;
    }

    .radio-btn-business-individual {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

    }


    .close-icon-xmark {
        position: fixed;
        top: 3%;
        left: 95%;
        cursor: pointer;
        font-size: 20px;
    }
}

.selected_file {
    margin-bottom: 22px;
    font-size: 14px;
}

@media (min-width: 1440px) and (max-width: 2540px) {
    .radio-btn-business-individual {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

    }

    .orange-color2 {
        margin-right: 100px;
    }

    .save-customer-container {
        justify-content: flex-start;

    }
}