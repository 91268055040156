.address-sidebar {
    top: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    z-index: 999;
    position: fixed;
    width: 48%;
    background-color: var(--background-color);
    color: var(--text-color);
    overflow: hidden;
    z-index: 99;
}

.new-order-name{
    margin-bottom: -5px;
}

.close-icon {
    position: absolute;
    top: 17px;
    right: 22px;
    cursor: pointer;
    font-size: 20px;
}

.scroll {
    overflow-y: auto;
    height: 70vh;
}

.scrollbar-address {
    height: calc(92vh - 130px);
    overflow-x: hidden;
    overflow-y: auto;
}

.address-line {
    font-size: 14px;
}

.select-address {
    font-size: 18px;
}

.customer-name {
    font-size: 16px;
}

.radio_btn {
    font-size: 14px;
}

.address_type {
    color: #9B9B9B;
    opacity: 1;
    font-size: 14px;
}

.address {
    font-size: 14px;
    position: relative;
    bottom: 16px;
}

.choose_btn {
    border-radius: 21px;
    border: none;
    background-color: #C0C2DE;
    height: 35px;
    font-size: 14px;
    width: 88px;
}

.choose_btn1 {
    border-radius: 21px;
    border: none;
    background-color: #C0C2DE;
    height: 35px;
    font-size: 14px;
    width: 88px;
    position: relative;
    top: -30vh;
    left: 35vh;
}

.choose_btn2 {
    border-radius: 21px;
    border: none;
    background-color: #C0C2DE;
    height: 35px;
    font-size: 14px;
    width: 88px;
    position: relative;
    top: -10vh;
    left: 22vh;
}

.CloseBtn {
    border-radius: 15px;
    font-size: 12px;
    width: 94px;
    height: 30px;
    border: none;
    outline: none;
    float: left;
    background-color: transparent;
    border: 1px solid grey;
    color: var(--text-color);
}

.cls-lcs {
    text-align: left;
}

.name-sel {
    position: relative;
    left: -428px;
    top: 21px;
}

.continue {
    position: relative;
    top: -72px;
    left: 1opx;
    left: -271px;
}

.fa-plus:before {
    float: right !important;
    font-size: 18px;
    color: black;
}

.cls {
    cursor: pointer
}

.cancle-addres {
    font-size: 18px;
    cursor: pointer;
    margin-left: 96%;
    bottom: 38px;
    position: relative;
    color: var(--text-color);
}