.set-button {
    position: absolute;
    bottom: 20px;
}

.close-icon-xmark-add {
    position: fixed;
    top: 2%;
    left: 95%;
    cursor: pointer;
    font-size: 16px;
}
.cust-title{
    margin-right: 65px;
}

.sidebar-wrapper-update {
    height: calc(98vh - 190px);
    overflow-x: hidden;
    overflow-y: auto;
    margin-right: 0;
    padding-right: 0;
}

.add-customeraddress-sidebar {
    top: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    position: fixed;
    width: 35%;
    background-color: var(--background-color) !important;
    color: var(--text-color) !important;
    overflow: auto;
    z-index: 1111;
}