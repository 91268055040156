.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background-color: #f0f1ff !important;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
}

.cancel-reset-icon {
    float: right;
}

.reset-style {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: none;
    outline: none;
}

.reset-submit-button {
    padding: 10px 20px;
    background-color: var(--btn-bg-color) !important;
    color: rgb(0, 0, 0) !important;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.reset-submit-button:hover {
    background-color: #0056b3;
}

.popup-message {
    font-size: 16px;
    color: #333;
    margin-bottom: 15px;
}