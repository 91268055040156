/* Add this CSS to your stylesheet */
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css');

.font14 {
  font-size: 14px;
}

.out_stock {
  font-size: 13px;
  font-weight: bold;
}

.text-colors {
  color: #4a4a4a;
}

.w-50 {
  width: 80px;
  height: 50px;
  object-fit: cover;
}

.inventory-pencil {
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.inventory-pencil:hover {
  transform: scale(1.1);
}

.ml-2 {
  margin-left: 0.5rem;
}

.inventory-row {
  transition: background-color 0.3s ease-in-out;
}

.inventory-row:hover {
  background-color: #f9f9f9;
}

.img-zoom {
  transition: transform 0.5s ease-in-out;
}

.img-zoom:hover {
  transform: scale(1.2);
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.inventory-name {
  font-size: 18px;
  padding-left: 10px;
  color: var(--text-color);
}

.inventory-text {
  font-size: 14px;
  padding-left: 10px;
  font-weight: 500;
  color: var(--text-color);
}

.inventory-product {
  width: 93%;
  background-color: transparent;
  border: 1px solid #C0C2DE;
  border-radius: 5px;
  color: var(--text-color);
  height: 38px;
}

.inventory-pencil {
  cursor: pointer;
  font-size: 14px;
  color: var(--text-color);
}

.inventory-hr {
  border: 1px solid grey;
  width: 95%;
  margin-left: 10px;
}

.update-inventory-cancel {
  margin-left: 87%;
  display: flex;
  position: relative;
  bottom: 23px;
  font-size: 17px;
}

tbody {
  color: var(--text-color);
}

.inventory-img {
  height: 18px;
  width: 20px;
  margin-left: 10px;
  margin-top: 3px;
  color: var(--text-color);
}

.company-name {
  color: #ED831A;
}

.table-header {
  position: sticky;
  top: -1px !important;
  background-color: #F0F1FF;
}

.sidebar-wrapper-i {
  height: calc(100vh - 180px);
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar-wrapper::-webkit-scrollbar {
  width: 5px;
  background-color: transparent !important;
}

.sidebar-wrapper::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

.pagination-container {
  display: flex;
  margin-top: 10px;
}

.pagination-item {
  cursor: pointer;
  margin: 0 5px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.cursor-pointer {
  cursor: pointer;
}

.pagination-item.active {
  background-color: #B5B8ED;
  color: #fff;
  width: 40px;
  text-align: center;
}

.hr-line-invetory {
  position: relative;
  bottom: 10px;
  margin-left: 10px;
  width: 95%;
  color: var(--hr-line-color);
}

.text-colors {
  color: var(--text-color);
}

.update-gst {
  height: 30px;
  font-size: 14px;
  padding-left: 2%;
  background: transparent;
  margin-left: 0%;
  width: 75%;
  border: 1px solid #C0C2DE;
  border-radius: 5px;
  color: var(--text-color);
}

.inventory-error {
  font-size: 15px;
}
