@import '../../../src/Theme/dark-theme.css';
@import '../../../src/Theme/light-theme.css';

.left-float {
  background-color: var(--background-color) !important;
  float: left;
  width: 6%;
}

.product-spinner {
  position: fixed;
  top: 50%;
  left: 58%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  color: var(--text-color);
}

.data-not {
  margin-top: 10%;
  margin-right: 30px;
}

.product-pagination {
  margin-left: 2%;
}

.product-hr {
  margin-top: 3px;
  margin-right: -20px;
}

.right-float {
  float: right;
  width: 94%;
}

.spinner-loader {
  text-align: center;
}

.error-line {
  text-align: center;
}

.products-item {
  margin-left: 14%;
}

.left-float {
  float: left;
  width: 6%;
  background-color: var(--background-color) !important;
  height: 100vh;
}

.feature-stars {
  color: var(--text-color);
  color: rgb(255, 93, 28);
}

.right-float {
  float: right;
  width: 94%;
}

.feature-pagination-button {
  border: 1px solid;
  border-radius: 3px;
  font-size: 14px;
  margin-left: 1%;
  margin-top: 7%;
  background-color: black;
}

.logo {
  height: 55px;
  width: 55px;
}

.feature-text {
  font-size: 10px;
}

.body_container {
  flex: 1 1;
  width: 50rem;
  height: 100vh;
  overflow-x: hidden;
  background-color: var(--secondary-background-color) !important;
}

body {
  background-color: var(--secondary-background-color) !important;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 5px;
}

.card_product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5% auto;
  width: 100%;
  height: 48vh;
  border-radius: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card_product:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.card_product:active {
  transform: scale(0.95);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.product-title {
  font-size: 14px;
  margin-top: 5%;
  color: var( --text-color-new);
  text-align: center;
}

.d-flex {
  display: flex;
  justify-content: center;
}

.feature-stars {
  margin: 0 2px;
}

.product_img {
  width: 100px;
  height: 135px;
  object-fit: cover;
}

.feature-price1 {
  font-size: 13px;
  color: var( --text-color-new);
  margin-left: 10px;
}

.feature-price2 {
  font-size: 13px;
  text-decoration: line-through;
  color: var( --text-color-new);
}

.grid-item img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.stars {
  font-size: 8px;
}

.feature-line {
  width: 96%;
  margin-left: 12px;
}

.product-title {
  font-size: 16px;
  margin-top: 5%;
  color: var( --text-color-new);
}

.rightSideMenu {
  height: 100vh;
  background-color: var(--background-color) !important;
}

.product-list-scroll {
  height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.product-list-container {
  display: block;
}

.product-list-container.hide {
  display: none;
}

.pagination1 {
  display: flex;
  list-style: none;
  padding: 0;
  position: fixed;
  margin-left: 10px;
  bottom: 10px;
  color: var(--text-color);
}

.pagination-limitdropdown {
  margin-top: 150px;
  margin-left: 22px;
}

.select-dropdown {
  width: 60px;
  padding: 6px;
  border: 1px solid #B5B8ED;
  border-radius: 2px;
  background-color: transparent;
  font-size: 14px;
  color: var(--text-color);
  outline: none;
  cursor: pointer;
}

.fix-input {
  margin-left: 0%;
}

@media (min-width:668px)and (max-width:1024px) {
  .logo {
    text-align: center;
  }

  .feature-text {
    font-size: 7px;
  }

  .product-title {
    font-size: 12px;
  }

  .feature-price1 {
    font-size: 12px;
  }

  .feature-price2 {
    font-size: 12px;
  }

  .feature-stars {
    font-size: 10px;
  }
}

@media (min-width:320px)and (max-width:600px) {
  .left-float {
    width: 14%;
  }

  .right-float {
    width: 86%;
  }

  .feature-text {
    font-size: 7px;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
  }
}

@media (min-width:768px)and (max-width:992px) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 5px;
  }
}

@media(min-width:110px) {}

.product-list-scroll::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.product-list-scroll::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

@media (min-width: 1440px) and (max-width: 2540px) {
  .card_product{
    width: 100%;
    height: 100%;

  }
  // .product_img {
  //   width: 100%;
  
  // }
  
}
@media (min-width: 1024px) and (max-width: 1440px) {
 
}
@media (min-width: 768px) and (max-width: 1024px) {
  .card_product {
    width: 50% !important;
    height: 100% !important;
}
}