.order-sidebar {
    top: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    width: 55%;
    background-color: var(--background-color);
    color: var(--text-color);
    z-index: 999;
}

.display_order {
    display: flex;
    // justify-content: space-between;
}
.plusIcon{
    color:var(--text-color);
    font-size: 20px;

}
.plusIcon-plus{
    color:var(--text-color);
    font-size: 20px;
}

.search-products {
    display: block;
    width: 102%;
}

.red-box {
    color: red;
    font-size: 13px;
}

.radio {
    font-size: 30px;
    color: var(--text-color);
}

.disc_input {
    color: var(--text-color);
    width: 66px;
    height: 36px;
    background-color: transparent;
    border: 1px solid #C0C2DE;
    text-align: center;
    outline: none;
    border-radius: 6px;
}

.order-details-padding {
    padding-left: 5px;
}

.selectoption {
    font-size: 14px;
    background-color: transparent;
    height: 34px;
    width: 224px;
    margin-left: 69px;
    margin-top: -4px;
    border: 1px solid #C0C2DE;
    position: relative;
    cursor: pointer;
}

.datePiker {
    color: #333;
    height: 34px;
    width: 100% !important;
    border: 1px solid #C0C2DE;
    font-size: 14px;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    border-radius: 7px;
}

.label {
    font-size: 18px;
    margin-left: -36px;
}

.date-picker-width-352 {
    width: 311px;
}

.deliveryitem {
    display: flex;
    justify-content: space-evenly;
    margin-left: -42px;
}

.Deliverytext {
    margin-left: -5px;
    color: rgb(248, 54, 54);
}

.order-sid-btn {
    margin-top: 2px;
    margin-right: 65%
}

.side-padding {
    padding-top: 20px;
    // padding-left: 30px;
    padding-right: 20px;
}

.service-request {
    text-align: left;
    line-height: 10px;
}

.date-time {
    text-align: right;
    line-height: 10px;
    margin-left: -2px;
}

.date-time-text {
    color: #C4C6E1;
    margin-top: -12px;
    margin-right: -28px;
}

.sr-hr {
    border: 1px solid #C0C2DE;
    width: 100%;
    position: relative;
}

.line-height {
    line-height: 10px;
}

.textPurple {
    color: #AA1FDD;
}

.textBlue {
    color: #1F68DD;
}

.hr-dash {
    border: 1px dashed #C0C2DE;
    width: 100%;
}

.text-orange1 {
    color: #FE8002;
}

.textOrange {
    color: #FE8002;
}

.textGreen {
    color: #2FCE5B;
}

.textSkyBlue {
    color: #0BB7CE;
}

.sidebar-wrapper1 {
    height: calc(98vh - 160px);
    overflow-x: hidden;
    overflow-y: auto;
    margin-right: 0;
    padding-right: 0;
}

.sidebar-wrapper::-webkit-scrollbar {
    width: 5px;
    background-color: transparent !important;
}

.sidebar-wrapper::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
}

.floating-getPayment-btn {
    position: relative;
    border-radius: 15px;
    font-size: 12px;
    width: 100px;
    left: 165px;
    margin-top: -4px;
    height: 30px;
    bottom: 25px;
    border: none;
    outline: none !important;
    background-color: #C0C2DE;
}

.saveDraft-btn,
.cancel-btn {
    border-radius: 15px;
    font-size: 12px;
    width: 100px;
    height: 30px;
    border: none;
    outline: none !important;
}

.cancel-btn {
    background-color: var(--background-color);
    border: 1px solid grey;
    color: var(--text-color);
}

.file-icon {
    font-size: 15px;
    padding-top: 5px;
}

.date-picker1,
.request-sidebar-datepicker {
    border: none;
    outline: none;
    background: transparent;
    padding-left: 60%;
}

.request-sidebar-datepicker {
    padding-top: 8%;
}

.service-request-datepicker {
    padding-top: 8%;
    padding-left: 50px;
}

.date-text {
    position: fixed;
    right: 42px;
}

.Date {
    margin-top: 26px;
}

.textarea-style {
    background: transparent;
    padding: 10px;
    border: 1px solid #C0C2DE;
    outline: none;
    color: var(--text-color);
}

.upload-input {
    border: none;
}

.input-style1 {
    height: 35px;
    background: transparent;
    border: none;
    border: 1px solid #C0C2DE;
    color: #333 !important;
    outline: none !important;
    width: 100% auto;
    padding-left: 10px;
}

.date-picker-style {
    width: 266px;
}

textarea {
    resize: none;
    height: 55px;
    width: 100%;
}

.buttonStyle {
    margin-top: -15px;
}

.view-doc {
    text-decoration: none;
}

.view-doc:hover {
    color: #C0C2DE !important;
    text-decoration: none;
}

.OrderDetails {
    height: 20px;
    text-align: left;
    letter-spacing: 0px;
    opacity: 1;
}

.Items {
    font-size: 16px !important;
    color: #807f7f;
}

.tax {
    position: relative;
    left: 116px !important;
    font-size: 16px !important;
    color: #807f7f;
}

// .classdate {
//     font-size: 14px !important;
// }

.pay_det {
    height: 20px;
    text-align: left;
    letter-spacing: 0px;
    opacity: 1;
}

.classfont_size {
    letter-spacing: 0px;
    font-size: 14px !important;
    font-weight: 400;
    cursor: pointer;
}

.color {
    color: rgb(146, 144, 144);
}

.total_a_sec label {
    font-size: 14px !important;
}

.search_input {
    width: 156px !important;
    height: 35px;
    border-radius: 25px;
    background-color: transparent;
    border: 1px solid #C0C2DE;
    outline: none;
}

.search_icon {
    position: absolute;
    right: 14px;
    top: 3px;
}

.search_color {
    color: #C0C2DE !important;
    font-size: 15px;
    margin-top: 5px;
}

.search_inpu {
    width: 200px;
    height: 35px;
    background-color: transparent;
    border: 1px solid #C0C2DE;
    padding-left: 8px;
    outline: none;
}

select {
    color: var(--text-color);
}

option {
    background-color: var(--secondary-background-color);
}

.search_ico {
    position: absolute;
    right: 2px;
    top: 6px;
}

.qty_input {
    color: var(--text-color);
    width: 55px;
    height: 36px;
    margin-left: 10px;
    background-color: transparent;
    border: 1px solid #C0C2DE;
    text-align: center;
    outline: none;
    border-radius: 6px;
}

.qty_input_dis {
    color: var(--text-color);
    width: 70px;
    height: 36px;
    // margin-left: 10px;
    background-color: transparent;
    border: 1px solid #C0C2DE;
    text-align: center;
    outline: none;
    border-radius: 6px;
}

.flues {
    margin-top: -10px;
    margin-left: 579px;
}

::placeholder {
    color: #C0C2DE !important;
    font-size: 14px;
}

.btn-processing {
    width: 81px;
    height: 26px;
    background: #A8EAE7 0% 0% no-repeat padding-box;
    border-radius: 14px;
    opacity: 1;
    border: none;
    margin-left: 13px;
    margin-top: -8px;
    font-size: 10px;
    position: relative;
    left: 35px;
}

.button1 {
    margin-left: 85px;
    margin-top: 16px;
    width: 71px;
    height: 35px;
    border: none;
    border: 2px solid #151414;
}

.btn-visible {
    height: 20px;
    width: 20px;
    border-radius: 33px;
}

.set-icon-visible {
    position: relative;
    right: 6px;
    bottom: 2px;
    font-size: 12;
}

.set-icon-visible1 {
    position: relative;
    right: 2px;
    bottom: 5px;
    font-size: 10px;
}

.qty_input1 {
    width: 70px;
    height: 33px;
    border: none;
    background-color: #C0C2DE;
    border: 1px solid #C0C2DE;
    text-align: center;
    outline: none;
    border-radius: 16px;
    font-size: 12px;
}

.qty_input2 {
    width: 92px;
    height: 30px;
    border: none;
    margin-left: 158px;
    background-color: #C0C2DE;
    border: 1px solid #C0C2DE;
    text-align: center;
    outline: none;
    border-radius: 15px;
}

th.Qty-UI,
th.Items,
th.Rate-UI,
th.Tax-UI,
th.Total-UI {
    color: var(--text-color);
}

td.CloseIcon,
td.ProductName.font14 {
    color: var(--text-color);
}

span.text-danger {
    font-size: 22px;
}

.add-product {
    margin-top: 6px;
    margin-left: -9px;
}

.add-order-btn {
    margin-top: 1.4px;
}

.Tax-UI {
    font-size: 16px !important;
}

tbody,
td {
    background: transparent !important;
}

.Delete {
    margin-left: 449px;
    background-color: #C0C2DE;
    height: 33px;
    position: relative;
    top: 49px;
    outline: none;
}

.fa-rectangle-xmark:before {
    cursor: pointer;
}

.category {
    margin-top: -8px;
    margin-left: 4px;
}

.department {
    position: relative;
    top: -13px;
}

.datePickerWrapper input {
    caret-color: transparent;
}

.cust-add {
    margin-left: 10px;
    margin-top: 10px;
    color: #2FCE5B !important;
    background: red;
}

.fa-plus-minus:before {
    content: "\e43c";
    color: black;
    font-size: 20px;
    position: relative;
    top: 10px;
}

.cis {
    cursor: pointer;
    padding-right: 30px;
}

.locateMap {
    margin-left: -50px;
}

.Click {
    margin-left: -50px;
}

.Shipping {
    margin-left: 330px;
    margin-top: -37px;
    margin-block-end: auto;
}

.error-border {
    border: 1px solid red;
}

.Savebtton {
    border-radius: 15px;
    font-size: 12px;
    width: 78px;
    height: 36px;
    border: none;
    outline: none;
    background-color: #C0C2DE;
    position: relative;
    top: -40px;
}

.select-warehouse-drop {
    color: var(--text-color);
    width: 30% !important;
    height: 36px;
    background-color: transparent;
    border: 1px solid #C0C2DE;
    text-align: center;
    outline: none;
    border-radius: 6px;
    cursor: pointer;
}

.plus-warehouse {
    color: var(--text-color);
    width: 5% !important;
    height: 36px;
    background-color: transparent;
    border: 1px solid #C0C2DE;
    outline: none;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    padding: 9px 8px;
}

.customDatePicker {
    color: #333;
    height: 34px;
    width: 256px;
    border: 1px solid #C0C2DE;
    font-size: 14px;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    border-radius: 5px;
    padding-left: 10px;
}

.input-style-assign {
    height: 35px;
    background: transparent;
    border: none;
    border: 1px solid #C0C2DE;
    color: var(--text-color) !important;
    outline: none !important;
    width: 96%;
    padding-left: 10px;
    border-radius: 5px;
}

.order-status {
    display: flex;
    justify-content: space-between;
}

.order-sid-btn-container {
    justify-content: flex-start;
}

/* 125 */
@media (min-width: 992px) and (max-width: 1199px) {
    .order-sid-btn-container {
        justify-content: flex-start;
    }
}

/* 175 */
@media (min-width: 768px) and (max-width: 899px) {
    .order-sid-btn-container {
        justify-content: flex-start;
    }
}

@media (min-width: 1214px) and (max-width: 1390px) {
    .date-picker-width-352 {
        width: 267px !important;
    }

    .datePiker {
        color: #333;
        height: 34px !important;
        width: 279px !important;
        border: 1px solid #C0C2DE !important;
        font-size: 14px !important;
        outline: none !important;
        background-color: transparent !important;
        cursor: pointer !important;
        border-radius: 5px !important;
    }

    .customDatePicker {
        width: 276px !important;
    }

    .order-sid-btn-container {
        justify-content: flex-start;
    }
}

@media (min-width: 1440px) and (max-width: 2540px) {
    .order-sid-btn-container {
        justify-content: flex-start;
    }
}