.filter-component {
    position: fixed;
    top: 0;
    right: 0;
    width: 30%;
    height: 100%;
    z-index: 9999;
    background-color:var(--background-color);
}

.min-price {
    margin-left: 12%;
    font-weight: 700;
    color: var(--text-color);
}

.max-price {
    margin-left: 12%;
    font-weight: 700;
    color: var(--text-color);
}

.price-bar-close {
    margin-left: 90%;
    cursor: pointer;
    
}

.max-price-input {
    border: 1px solid #C0C2DE;
    padding: 4px;
    margin-left: 12%;
    background-color: transparent;
    color: var(--text-color);
    
}

.min-price-input {
    border: 1px solid #C0C2DE;
    padding: 4px;
    margin-left: 12%;
    background-color: transparent;
    color: var(--text-color);
}

.feature-filter-title {
    margin-left: 12%;
    font-weight: 800;
    font-size: 18px;
    color: var(--text-color);
}

.feature-filter-select {
    border: 1px solid #000000;
    opacity: 1;
    padding: 4px;
    margin-left: 50%;
    width: 100%;
    background-color: transparent;
}

.apply-filter {
    border: none;
    background: #C0C2DE;
    color: black;
    height: 34px;
    border-radius: 18px;
    width: 18%;
    margin-left: 12%;
}

.price-filter-cancel {
    margin-left: 3%;
    color:var(--text-color);
    height: 34px;
    border-radius: 18px;
    width: 18%;
    border: 1px solid #C0C2DE;
    background: transparent;
}

.category-label,
.tags-label {
    margin-left: 12%;
}

.category-input,
.tags-input {
    margin-left: 12%;
    border: 1px solid black;
    padding: 4px;
    background-color: transparent;
}