.table-scroll-sto {
  height: calc(102vh - 180px);
  overflow-x: hidden;
  overflow-y: auto;
  margin-right: 0;
  padding-right: 0;
  scrollbar-color: transparent transparent;
}

.search-icon-stock {
  top: 10px;
  position: absolute;
  transform: translatex(80%);
  font-size: 15px;

}
.delete-message{
  color: var(--text-color-new);
}


.search-member-stock {
  position: relative;
  left: 89px;
}

.STO-button {
  background-color: #C0C2DE;
  color: black;
  padding: 5px;
  border-radius: 25px;
  border: none;
  height: 38px;
  width: 90px;
  padding-top: 8px;
  font-size: 15px;
  cursor: pointer;
  margin-top: 2px;
}

.pagination2 {
  display: flex;
  list-style: none;
  padding: 0;
  position: fixed;
  bottom: 5px;
  color: var(--text-color);
}

.pagination-stodropdown {
  margin-left: 10px;
}

.select-stodropdown {
  width: 60px;
  padding: 6px;
  border: 1px solid #B5B8ED;
  border-radius: 2px;
  background-color: transparent;
  font-size: 14px;
  color: var(--text-color);
  outline: none;
  cursor: pointer;
}