.Intent-sidebar {
    z-index: 999;
    top: 0;
    right: 0;
    width: 52%;
    height: 100%;
    position: fixed;
    overflow: hidden;
    overflow-y: auto;
    background-color: var(--background-color);
    transition: margin-right 4.3s ease;
}

.products-descriptions {
    overflow: hidden;
    z-index: 9999;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    color: var(--text-color);
    overflow-y: auto;
}

.intent-id {
    color: var(--text-color);
}

.intent-supplier {
    color: var(--text-color);
}

.intent-date {
    color: var(--text-color);
}

.intent-warehouse-name {
    color: var(--text-color);
}

.Intent-create-title {
    margin-top: 10%;
    font-weight: 600;
    color: var(--text-color);
}

.intent-trash {
    cursor: pointer;
}

.intent-qty {
    padding: 6px;
    color: black;
    border-radius: 5px;
    width: 30%;
    background-color: transparent;
    border: 1px solid rgb(202, 202, 202);
    height: 37px;
    margin-left: 24px;
    text-align: center;
}

.hrrr {
    width: 100%;
    margin-left: 0px;
    color: var(--text-color);
}

.quantity-add-intent-button {
    background-color: #c2c2e8;
    color: black;
    width: 45%;
    height: 32px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    margin-left: 50px;
    margin-top: 36px;
}

.quantity-add-intent {
    background-color: #c2c2e8;
    color: black;
    width: 35%;
    height: 32px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    margin-left: 110px;
}

.warehouse-add-intent {
    background-color: #c2c2e8;
    color: black;
    width: 35%;
    height: 32px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    margin-left: 110px;
    margin-top: 36px;
}

.intent-cancel {
    margin-left: 1px;
    padding: 5px;
    width: 16%;
    height: 33px;
    font-size: 14px;
    margin-top: 0%;
    background-color: transparent;
    color: var(--text-color);
    border: 1px solid #727272;
    border-radius: 20px;
    cursor: pointer;
}

.intent-save {
    padding: 5px;
    width: 16%;
    height: 33px;
    background-color: #c2c2e8;
    color: black;
    font-size: 14px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
}

.intent-number-input {
    width: 29%;
    margin-left: 3%;
    margin-top: 5%;
    padding: 5px;
    background-color: transparent;
    color: black;
    border-radius: 5px;
    border: 1px solid #727272;
}

.scroller-bar {
    height: calc(95vh - 160px);
    overflow-y: auto;
    overflow-x: hidden;
}

.scroller-bar-intent {
    height: calc(100vh - 160px);
    overflow-y: auto;
    overflow-x: hidden;
}

.row {
    display: flex;
}

.type-head {
    width: 110%;
    height: 34px;
    background-color: transparent;
    color: var(--text-color);
}

.intent-delivery-date {
    font-size: 14px;
    height: 36px;
    width: 170px;
    border: 1px solid #C0C2DE;
    position: relative;
    cursor: pointer;
    outline: none;
    padding-left: 10px;
    color: #333;
}

.intent-delivery-date {
    border-radius: 5px;
    color: var(--text-color) !important;
    background-color: var(--background-color) !important;
}