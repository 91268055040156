@import url('https://fonts.googleapis.com/css2?family=Baloo+2&display=swap');

.profile-sidebar {
    top: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    padding: 10px;
    height: 100vh;
    float: right;
    z-index: 999;
    width: 33%;
    background-color: var(--background-color);
    overflow-y: scroll;
    overflow-x: hidden;
}

.scrollbar-service {
    height: calc(100vh - 160px);
    overflow-x: hidden;
    overflow-y: auto;
}

.set-width {
    width: 100%;
}

.color-pick {
    background-color: #C0C2DE;
    color: black;
    border: 1px solid #C0C2DE;
}

.service-subtitles {
    color: grey;
    font-size: 14px;
    color: var(--text-color);
}

.service-hrs {
    width: 100%;
}

.request-title {
    font-family: 'Baloo 2', sans-serif;
    color: var(--text-color);
}

.chrome-picker-small {
    width: 100px;
}

.service-sidebar-close {
    float: right;
    background: none;
    border: none;
    color: var(--text-color);
}

.service-sidebar-close1 {
    margin-left: 235px;
    background: none;
    border: none;
    cursor: pointer;
}

.service-input {
    width: 95%;
    border: 1px solid #C0C2DE;
    background-color: transparent;
    padding: 4px;
    height: 35px;
    border-radius: 5px;
    color: var(--text-color);
}

.service-inpt {
    width: 100%;
    border: 1px solid #C0C2DE;
    background-color: transparent;
    padding: 4px;
    height: 35px;
    border-radius: 5px;
    color: var(--text-color);
}

.dropdown-menu.show {
    width: 220px !important;
    background-color: var(--secondary-background-color) !important;
    color: var(--text-color) !important;
}

.service-input-1 {
    width: 95%;
    border: 1px solid #C0C2DE;
    background-color: transparent;
    padding: 4px;
    height: 75px;
    outline: none;
    border-radius: 5px;
    color: var(--text-color);
}

.service-inpt-1 {
    width: 100%;
    border: 1px solid #C0C2DE;
    background-color: transparent;
    padding: 4px;
    height: 75px;
    outline: none;
    border-radius: 5px;
    color: var(--text-color);
}

.css-tj5bde-Svg {
    background-color: var(--background-color);
}

.service-input-3 {
    width: 95%;
    border: 1px solid #C0C2DE;
    background-color: transparent;
    padding: 12px;
    height: 35px;
    border-radius: 5px;
    color: var(--text-color);
}

.service-inpt-3 {
    width: 100%;
    border: 1px solid #C0C2DE;
    background-color: transparent;
    padding: 12px;
    height: 35px;
    border-radius: 5px;
    color: var(--text-color);
}

.service-select {
    background-color: var(--background-color);
    padding: 4px;
    height: 30px;
    margin-top: -12px;
    color: var(--text-color);
}

.service-select1 {
    background-color: var(--background-color);
    padding: 4px;
    height: 30px;
    margin-top: 1px;
    color: var(--text-color);
}

.service-option {
    font-family: 'Baloo 2', sans-serif;
}

.service-tag {
    width: 92%;
    background-color: transparent;
    border: 1px solid #C0C2DE;
    border-radius: 5px;
    padding: 5px;
    height: 35px;
    color: var(--text-color);
}

.service-tag1 {
    width: 100%;
    background-color: transparent;
    border: 1px solid #C0C2DE;
    border-radius: 5px;
    padding: 5px;
    height: 35px;
    color: var(--text-color);
}

.service-url {
    width: 95%;
    border: 1px solid #C0C2DE;
    padding: 8px;
    border-radius: 5px;
    background-color: transparent;
    height: 35px;
    color: var(--text-color);
}

.service-web-url {
    width: 100%;
    border: 1px solid #C0C2DE;
    padding: 8px;
    background-color: transparent;
    border-radius: 5px;
    height: 35px;
    color: var(--text-color);
}

.checkbox-feature {
    font-family: 'Baloo 2', sans-serif;
    margin-left: 2px;
    color: var(--text-color);
}

.service-save {
    border: none;
    background: #C0C2DE;
    color: black;
    height: 34px;
    border-radius: 18px;
    width: 25%;
}

.service-category-select {
    width: 95%;
    height: 35px;
    margin-bottom: 18px;
    margin-top: 3px;
    color: var(--text-color);
}

.service-create-select {
    width: 100%;
    height: 38px;
    border-radius: 5px;
    margin-bottom: 18px;
    margin-top: 3px;
    color: var(--text-color);
}

.service-cancel {
    color: var(--text-color);
    height: 34px;
    border: 1px solid #C0C2DE;
    background: transparent;
    border-radius: 22px;
    width: 25%;
    margin-left: 3%;
}

.scrollbar-employee::-webkit-scrollbar {
    width: 5px;
    background-color: rgb(202, 201, 201);
    background-color: transparent;
}

.scrollbar-employee::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
}