:root {
  --background-color: #f1e3ad;
  --secondary-background-color: #E7E8FA;
  --secondary-background: 0px 1px 8px 4px #dcdded;

  --text-color: #333;
  --icon-color: #333;
  --text-color-new: #333;
  --text-color-product: #fff;

  --btn-bg-color: #C0C2DE;
  --btn-background-color: #DFE0F5;
  --thead-bg: #b6479e;

  --sidebar-bg-tooltip: black;
  --sidebar-tooltip-color: white;
  --table-hover: #C0C2DE;

}