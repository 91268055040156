.invertery-sidebar {
    top: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    float: right;
    z-index: 999;
    position: fixed;
    color: var(--text-color);
    width: 40%;
    background-color: var(--background-color);
    overflow: auto;
}

.inventery-box {
    padding-left: 25px;
    margin-top: 18px;
}

.manager-name{
    position: relative;
    right: 30px;
}

.enventary-input {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #c9c0c0;
    width: 75%;
    font-size: 14px;
    background: transparent;
}

.inventery-text-up {
    font-size: 16px;
    color: var(--text-color);
}

.inventery-text {
    font-size: 16px;
    color: var(--text-color);
}

.inventery-text-sub {
    font-size: 15px;
    position: relative;
    bottom: 15px;
    color: var(--text-color);
}

.inventory-products {
    width: 93%;
    background-color: transparent;
    border-radius: 5px !important;
}

.inventory-date {
    width: 314%;
    background-color: transparent;
    border: 1px solid #C0C2DE;
    border-radius: 5px;
    height: 38px;
}

.inventory-date-u {
    width: 76vh;
    background-color: transparent;
    border: 1px solid #C0C2DE;
    border-radius: 5px;
    height: 38px;
    color: var(--text-color);
}

.inventory-supplier-input {
    width: 93%;
}

.enventery-input-2 {
    width: 79vh !important;
    background-color: transparent;
    border: 1px solid #C0C2DE;
    border-radius: 5px;
    height: 35px;
}

.order-supplier-input {
    width: 35%;
}

.inventory-supplier-input-Add {
    width: 93%;
    border-radius: 5px;
}

.inventory-product-input {
    margin-top: 5%;
}

.inventery-hr-up {
    width: 91%;
    color: var(--text-color);
}

.inventery-hr {
    width: 94%;
    margin-top: -3%;
    color: var(--text-color);
}

.color-color {
    color: var(--text-color) !important;
}

.cancle-icon-inventery {
    font-size: 18px;
    cursor: pointer;
    margin-left: 89%;
    bottom: 20px;
    position: relative;
    color: var(--text-color);
}

.supplier-btn {
    background-color: #D3D3E5;
    border-radius: 18px;
    border: none;
    font-size: 14px;
    top: 410px;
    left: 1731px;
    width: 142px;
    height: 30px;
    margin-left: 50px;
}

.btn-red {
    height: 30px;
    background-color: black;
    width: 60px;
    color: white;
    border-radius: 18px;
    border: 1px solid black;
    font-size: 14px;
}

.btn-green {
    height: 30px;
    background-color: #D3D3E5;
    width: 60px;
    color: black;
    border-radius: 18px;
    border: 1px solid #D3D3E5;
    font-size: 14px;
}

.inty-side {
    border-radius: 5px;
}

.scrollbar-enventary {
    height: calc(100vh - 185px);
    overflow-x: hidden;
    overflow-y: auto;
}

.enventery-input {
    background-color: transparent;
    border: 1px solid #C0C2DE;
    padding: 5px;
    font-size: 14px;
    width: 93%;
    border-radius: 5px;
    color: var(--text-color);
}

.enventery-input-search {
    background-color: #ECECF5;
    border: 1px solid #727272;
    width: 55%;
    border-radius: 4px;
}

.inventery-icon {
    margin-left: -22px;
    color: #727272;
    font-size: 15px;
}

.variants-btn {
    border-radius: 12px;
    background-color: #D3D3E5;
    padding-left: 8px;
    padding: 6px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.my-button.hovered {
    background-color: #000000;
}

.my-button.clicked {
    transform: scale(0.95);
}

.cgst-input {
    background-color: transparent;
    border: 1px solid #c0c0c0;
    border-radius: 5px;
    width: 85%;
}

.sgst-input {
    background-color: transparent;
    border: 1px solid #c0c0c0;
    width: 85%;
    border-radius: 5px;
}

.inventory-error {
    color: red;
}

.igst-input {
    background-color: transparent;
    border: 1px solid #c0c0c0;
    width: 79%;
    border-radius: 5px;
}

.select_product-input {
    width: 16vh;
    border-width: 1px;
    font-size: 12px;
    background-color: #ECECF5;
    border-radius: 0px;
    border: 1px solid gray;
    height: 40px;
    width: 91%;
}

.variants-btn.selected {
    background-color: black;
    color: white;
}

.dropdown-menu.show {
    width: auto !important;
}

.varient-pricelist-table {
    width: 93%;
    color: var(--text-color);
}

.scrollbar-enventary::-webkit-scrollbar {
    width: 5px;
    background-color: rgb(202, 201, 201);
    background-color: transparent;
}

.scrollbar-enventary::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
}

.update-inventery-btn {
    border: none;
    background: #C0C2DE !important;
    color: black;
    height: 34px;
    border-radius: 18px;
    width: 18%;
    font-size: 13px;
}

.cancel-inventery-btn {
    color: var(--text-color) !important;
    height: 34px;
    border: 1px solid #C0C2DE;
    width: 74px;
    background: transparent;
    border-radius: 22px;
    width: 18%;
}