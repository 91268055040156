.emp-delete {
  cursor: pointer;
  height: 33px;
  width: 55px;
  color: #ffffff;
  background-color: #000000;
  border: none;
  transform: translate(40px, 10px);
  margin-left: 25px;
}

.emp-nodelete {
  cursor: pointer;
  height: 33px;
  width: 55px;
  transform: translate(40px, 10px);
  margin-left: 20px;
  color: #333;
  background-color: #ffffff;
  border: 1px solid gray;
  background-color: transparent !important;
}

.emp-cust-box {
  position: relative;
  width: 25%;
  font-size: 16px;
  min-height: 140px;
  background-color: #F0F1FF;
  border-radius: 5px;
  margin: 275px auto;
  padding: 18px;
  padding-left: 30px;
  overflow: auto;
  margin-left: 30%;
  bottom: 70px;
  transform: translate(145px, 10px);
}

.txt-delete {
  padding-top: 5px;
  padding-right: 30px;
  color: #333;
}