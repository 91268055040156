.terms_Sidebar {
    position: fixed;
    overflow-x: hidden;
    z-index: 999;
    transition: margin-right 0.3s ease;
    top: 0;
    right: 0;
    width: 48%;
    height: 100%;
    background-color: var(--background-color);
    overflow-y: auto;
}

.terms_scroller-bar {
    height: calc(100vh - 165px);
    overflow-y: auto;
    overflow-x: hidden;
}

.module_add {
    border: 1px solid grey;
    border-radius: 3px;
    height: 34px;
    width: 180px;
    background-color: var(--background-color);
    padding-left: 10px;
}

.terms_add {
    border: 1px solid grey;
    border-radius: 3px;
    height: 34px;
    width: 87%;
    background-color: var(--background-color);
    padding-left: 10px;
    color: var(--text-color);
}

.sidebar_head {
    display: flex;
    justify-content: space-between;
}

.drop-module {
    padding: 3PX;
    color: #A3A3A6;
}

.drop-term {
    margin-bottom: 8px;
}

.dropdown_term {
    position: relative;
    bottom: 1px;
    color: var(--text-color) !important;
    background-color: var(--secondary-background-color) !important;
}

.list_term {
    position: relative;
    right: 2px;

}

.terms-line {
    display: flex;
}

.terms-cancel {
    width: 80px;
    height: 31px;
    margin-left: 10px;
    background-color: transparent;
    color: var(--text-color);
    border: 1px solid var(--text-color);
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
}

.terms-btn-save {
    display: flex;
    position: relative;
    top: 18px;
}

.terms-save {
    width: 80px;
    height: 31px;
    background-color: #c2c2e8;
    color: black;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
}

.close-term {
    position: relative;
    top: 25px;
    left: 6px;
    font-size: 20px;
}

.add_terms {
    background-color: #C0C2DE;
    color: black;
    border-radius: 3px;
    border: none;
    height: 34px;
    width: 10%;
    padding-top: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-left: 15px;
}

.side_padding {
    padding-left: 20px;
    padding-right: 20px;
}

.terms-delete-icon {
    float: inline-end;
}

.list_term ol {
    list-style: none;
    padding-left: 0;
}

.list_term li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 3px;
}

.delete-icon {
    margin-left: 10px;
    cursor: pointer;
}