@import '../../../src/Theme/dark-theme.css';
@import '../../../src/Theme/light-theme.css';
@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@500&display=swap');

* {
  font-family: 'Baloo 2', sans-serif;
}

.light-theme {
  --background-color: #F0F1FF;
  --secondary-background-color: #E7E8FA;
  --tertiary-background-color: #E1E2F7;
  --round-background-color: #101111;
  --text-color: #333;
  --icon-color: #333;
  --sidebar-bg-tooltip: black;
  --sidebar-tooltip-color: white;
  --thead-bg: #F0F1FF;
  --graph-bg: #F0F1FF;
  --plus-button: #E1E2F7;
  --search-input-color: #E1E2F7;
  --hr-line-color: gray;
  --arrow-icon-next: black;
  --close-icon: gray;
  --color-text: black;
}

.dark-theme {
  --background-color: #37364B;
  --secondary-background-color: #303143;
  --tertiary-background-color: #2f3044;
  --round-background-color: #37364B;
  --text-color: #fff;
  --icon-color: #fff;
  --sidebar-bg-tooltip: black;
  --sidebar-tooltip-color: white;
  --thead-bg: 37364B;
  --graph-bg: #37364B;
  --card-shadow: #3d3c50;
  --plus-button: #E1E2F7;
  --search-input-color: #E1E2F7;
  --hr-line-color: #E1E2F7;
  --arrow-icon-next: white;
  --close-icon: white;
  --color-text: black;
}

.responsive-scroll-bar {
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.no-data {
  background: transparent !important;
  text-align: center;
  position: fixed;
  top: 40%;
  left: 50%;
}

.responsive-scroll-bar1 {
  height: calc(100vh - 180px);
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-color: transparent transparent;
}

.customer-request-title {
  font-size: 18px;
  font-weight: 900;
  color: var(--text-color) !important;
}

.table-location {
  width: 20px;
}

.sub-title {
  font-size: 14px;
  color: var(--text-color);
  font-weight: 500;
}

.capitalize-first-letter {
  text-transform: capitalize;
}

.line-height {
  line-height: 10px;
}

.select_flex {
  display: flex;
  justify-content: space-between;
}

.dropdown_flex {
  display: flex;
}

.t-data {
  background-color: transparent !important;
  border: 1px solid #B5B8ED;
  border-left: 0;
  border-right: 0;
  margin-top: 0px;
  color: var(--text-color) !important;
}

.LODER {
  position: absolute;
  margin-left: 480px;
  margin-top: 150px;
}

.table-row {
  background-color: #E7E8FA;
}

.locate-color {
  color: #0D58BE !important;
}

.status-color {
  color: #2FCE5B !important;
}

.lineHeight0 {
  line-height: 0;
}

.border-bottom {
  border: none;
  background: transparent;
  border-bottom: 1px solid #C9C9C9 !important;
  width: 274px;
}

.search-input {
  outline: none;
  color: #333;
  width: 30vh !important;
}

.cust-search-input {
  outline: none;
  color: #333;
  width: 35vh !important;
}

.select-year {
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
}

.drop-item {
  padding: 3PX;
  color: #A3A3A6;
}

.drop-list {
  margin-bottom: 8px;
}

.delete_btn {
  height: 34px
}

.select-month {
  font-weight: 500;
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="checkbox"]::before {
  content: '\2713';
  display: inline-block;
  width: 13px;
  height: 14px;
  border: 1px solid #B5B8ED;
  background-color: transparent;
  margin-right: 5px;
  text-align: center;
  font-size: 9px;
  color: transparent;
}

input[type="checkbox"]:checked::before {
  background-color: #000;
  color: #fff;
}

.btn_primary-cust {
  padding: 5px 2px;
  border: 0px solid #C0C2DE;
  border-radius: 20px;
  width: 110px;
  font-weight: 500;
  margin: 5px 10px 10px 0px;
  background-color: #C0C2DE;
  cursor: pointer;
  align-items: center;
  height: 34px;
}

.main-padding-customer1 {
  padding-top: 19px;
  padding-left: 36px;
  padding-right: 30px;
}

.delete-addnew-btn {
  position: relative;
  margin-top: -9px;
}

.dot-icon {
  font-size: 20px;
}

.btn_disable {
  font-size: 14px;
  padding: 5px 2px;
  border: 0px solid #C0C2DE;
  border-radius: 20px;
  height: 34px;
  font-weight: 500;
  margin: 6px 10px 10px 8px;
  background-color: #ccc;
  color: #201f1f;
  align-items: center;
  margin-left: 100px;
}

.btn_primary_outline {
  padding: 5px 2px;
  border: 1px solid #d0cbcb;
  border-radius: 20px;
  width: 95px;
  color: #000;
  font-weight: 500;
  margin: 6px 10px 10px 0px;
  background-color: #fff;
  cursor: pointer;
  align-items: center;
}

.light-button {
  font-size: 14px;
  padding: 5px 2px;
  border: 0px solid #c0c2de;
  border-radius: 20px;
  width: 95px;
  font-weight: 500;
  background-color: #ccc;
  color: #a1a1a1;
  align-items: center;
  height: 33px;
}

.dropdown_head {
  position: relative;
  bottom: 1px;
  color: var(--text-color) !important;
  background-color: var(--secondary-background-color) !important;
}

.table-scroll-sr {
  height: calc(102vh - 270px);
  overflow-x: hidden;
  overflow-y: auto;
  margin-right: 0;
  padding-right: 0;
  scrollbar-color: transparent transparent;
}

.table-scroll-sr1 {
  height: calc(102vh - 190px);
  overflow-x: hidden;
  overflow-y: auto;
  margin-right: 0;
  padding-right: 0;
  scrollbar-color: transparent transparent;
}

.trip {
  height: 48vh !important;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}

.fix-input {
  margin-left: 6%;
}

.table-container {
  overflow-x: auto;
  max-height: 400px;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid gray !important;
}

.table-head {
  position: sticky !important;
  top: -1px !important;
  background-color: #F0F1FF;
  overflow-y: hidden;
  z-index: 99;
  border-bottom: 1px solid gray !important;
}

.main {
  display: flex;
  color: #333;
}

.container-container {
  display: flex;
  width: 100%;
  transition: margin-left 0.3s;
  overflow: hidden;
  padding: 0px;
}

.select-year-cust{
  width: 120px;
}

.body_container {
  flex: 1 1;
  width: 50rem;
  height: 100vh;
  overflow-x: hidden;
  background-color: var(--secondary-background-color) !important;
}

.main-padding1 {
  padding-top: 10px;
  padding-left: 22px;
  padding-right: 30px;
}

.right-sidemenu {
  background-color: #ECECF5;
}

.middle-side {
  background-color: #F5F5FC;
  overflow-x: auto;
  height: 100vh;
}

//pagination css
.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  position: fixed;
  bottom: 8px !important;
}

.pagination-bar {
  display: flex;
  list-style: none;
  padding: 0;
}

.pagination-bar li {
  margin: 0 5px;
}

.pagination-bar li a {
  padding: 3px 10px;
  border: 1px solid #ccc;
  color: #007bff;
  cursor: pointer;
  text-decoration: none;
}

.pagination-bar li.active a {
  background-color: #007bff;
  color: white;
}

.pagination-bar li a:hover {
  background-color: #e9ecef;
}

.pagination-bar li a:focus {
  outline: none;
}

.cardstyle-cust {
  margin-top: 10px;
  width: 150px;
  height: 71px;
  margin-left: 2px;
  background: var(--card-bg) 0% 0% no-repeat padding-box;
  color: var(--text-color);
  box-shadow: 0px 1px 8px 4px #dcdded;
  border-radius: 5px;
  opacity: 1;
  background-color: var(--background-color);
  border: none;
}

.totalcustomercolor {
  color: rgb(160, 32, 240);
}

.card-name {
  color: #766b6b;
  font-weight: 100;
  margin: 2px;
  color: var(--text-color);
  margin-top: 8px;
  font-size: 14px;
}

.card-num {
  font-weight: bold;
  font-size: 121%;
  margin-top: -4px;
}


@media (min-width: 992px) and (max-width: 1199px) {
  .font14 {
    font-size: 13px !important;
  }

  .date-picker-style {
    width: 183px;
  }
}

@media (min-width: 1200px) and (max-width: 1365px) {
  .border-bottom {
    width: 175px !important;
  }

  .search-input {
    width: 36vh !important;
  }

  .fix-input {
    margin-left: 2%;
  }
}

@media (min-width: 768px) and (max-width: 899px) {
  .font14 {
    font-size: 12px !important;
  }
}

@media (min-width: 900px) and (max-width: 991px) {
  .font14 {
    font-size: 12px !important;
  }
}