.search-supplier {
    padding-left: 9%;
    font-size: 15px;
    position: absolute;
    right: 12%;
    bottom: 80%;
    position: fixed;
    top: 100px;
}

.customers {
    font-weight: 600;
    font-size: 18px;
}

.text-color {
    color: var(--text-color);
}

.BankDeteails {
    color: var(--text-color);
}

.customer-title {
    color: var(--text-color);
}
.supplier-theme{
    color: var(--text-color);

}
.popup-box-supplier {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    top: 0;
    left: 0;
    background: rgb(0 0 0 / 7%);
    
}

.signed-members-subtitle {
    font-size: 16px;
    font-weight: 500;
    color: var(--text-color);
}

.microsoft {
    height: 20px;
    cursor: pointer;
    position: absolute;
    right: 41%;
}

.microsoft-ex {
    position: absolute;
    right: 43%;
    margin-top: 5px;
}

.filter-ico {
    position: absolute;
    top: 10px;
    right: 15%;
}

.filte {
    position: relative;
    left: 90%;
    margin-bottom: 20px;
    width: 37px !important;
    height: 38px !important;
    background: var(--unnamed-color-f5f5fc) 0% 0% no-repeat padding-box !important;
    background: var(--bg-click-button) 0% 0% no-repeat padding-box;
    box-shadow: 6px 6px 12px rgba(105, 105, 105, 0.1607843137);
    border-radius: 12px;
}

.suppliers-sidebar {
    top: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    float: right;
    z-index: 999;
    position: fixed;
    width: 50%;
    background-color: var(--background-color);
    overflow: hidden;
    padding-right: 0;
}

.supplier-sidebars {
    top: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    float: right;
    z-index: 999;
    position: fixed;
    width: 35%;
    background-color: var(--background-color);
    overflow: hidden;
    padding-right: 0;
}

.table-body-color {
    background-color: #ECECF5;
    max-height: 300px;
    overflow-y: auto;
    font-size: 14px;
}

.suppliers-padding {
    padding-left: 26px;
    padding-top: 24px;
    margin-right: 20px;
}

.suppliers-padding1 {
    padding-left: 26px;
    margin-right: 20px;
}

.suppliers-style {
    font-size: 18px;
    font-weight: 800;
    color: var(--text-color);
}

.suppliers-style1 {
    font-size: 18px;
    font-weight: 800;
    color: var(--text-color);
}

.suppliers-hr {
    width: 98%;
    color: var(--hr-line-color);
}

.supplier_hr {
    margin-top: 0px;
}

.profile-hr {
    color: var(--hr-line-color);
}

.save-supplier {
    border: none;
    background: #C0C2DE;
    color: black;
    height: 34px;
    border-radius: 18px;
    width: 18%;
}

.inputess {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #C0C2DE;
    width: 100%;
    font-size: 14px;
    background: transparent;
    color: var(--text-color);
}

.currency-supplier {
    color: var(--text-color);
    padding: 4px;
    border-radius: 5px;
    border: 1px solid #C0C2DE;
    width: 100%;
    height: 35px;
    font-size: 14px;
    background: transparent;
}

.inputes-payment {
    padding: 8px;
    border-radius: 0px;
    border: 1px solid #847C7C;
    width: 280%;
    font-size: 14px;
    background: transparent;
}

.info-icons {
    font-size: 12px;
}

.profile-page11 {
    padding-top: 4px;
    line-height: 7px;
}

.cancel-supplier {
    color: var(--text-color);
    height: 34px;
    border: 1px solid #C0C2DE;
    width: 74px;
    background: transparent;
    border-radius: 22px;
    width: 18%;
}

.edit-icon,
.supplier-delete-icon {
    height: 15px;
    padding-left: 6px;
    color: var(--icon-color);
    cursor: pointer !important;
}

.supplier-scrollar {
    height: calc(97vh - 103px);
    overflow-y: auto;
}

.supplier-search {
    position: relative;
    left: 25px;
}

.supplier-scrollar2 {
    height: calc(98vh - 178px);
    overflow-y: auto;
}

.supplier-scrollar1 {
    height: calc(97vh - 175px);
    overflow-y: auto
}

.sidebar-wrapper {
    height: calc(100vh - 180px);
    overflow-x: hidden;
    overflow-y: auto;
    margin-right: 0;
    padding-right: 0;
}

.supplier-sidebar-wrapper::-webkit-scrollbar {
    width: 5px;
    background-color: transparent !important;
}

.supplier-sidebar-wrapper::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
}

.inputes-country {
    padding: 8px;
    color: var(--text-color);
    border-radius: 5px;
    border: 1px solid #C0C2DE;
    width: 94%;
    font-size: 14px;
    background: transparent;
}

.customer-margin-left {
    margin-left: 10px;
}

.edit-profile {
    cursor: pointer;
    font-size: 16px;
    color: var(--text-color);

}