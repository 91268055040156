.logpopup-box {
    position: fixed;
    background: #00000050;
    width: 130%;
    height: 110vh;
    z-index: 9999;
    margin-right: 200px;
    bottom: 0px;
  }
  
  .error-border {
    border: 1px solid red;
  }
  
  .logout-box {
    position: relative;
    width: 15%;
    min-height: 116px;
    background-color: #F0F1FF;
    border-radius: 4px;
    font-size: 13px;
    margin: 275px auto;
    padding: 18px;
    padding-left: 31px;
    overflow: auto;
    transform: translate(145px, 10px);
    box-shadow: 0 7px 10px 15px rgba(0, 0, 0, 0.10);}
  
  .btn-close {
    cursor: pointer;
    position: fixed;
    right: calc(40% - 38px);
    top: 25px;
    border-radius: 50%;
    color: #fff;
    background-color: rgb(12, 12, 12);
    border: 1px solid #999;
    font-size: 14px;
  }
  
  .logout-delete {
    cursor: pointer;
    height: 30px;
    color: #ffffff;
    background-color: #000000;
    border: none;
    transform: translate(40px, 10px);
    margin-left: 15px;
    margin-top: 0px;
  }
  
  .logout-nodelete {
    cursor: pointer;
    height: 30px;
    transform: translate(40px, 10px);
    margin-left: 14px;
    color: #333;
    border: 1px solid gray;
    background-color: transparent;
  }
  
  .text-delete {
    padding-top: 5px;
    padding-left: 6px;
    color: #333;
  }