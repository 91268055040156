.main-customer {
    margin-top: 1%;
}

.ware-title {
    font-size: 18px;
    color: var(--text-color);
}

.ware-subline {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-color);
}

.category-title {
    font-size: 16px;
    color: var(--text-color);
}

.customers {
    font-size: 18px;
    color: var(--text-color);
}

.people-fill {
    margin-top: 10px;
}

.sub-titles {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 500;
    color: var(--text-color);
}

.product-title1 {
    font-size: 18px;
    font-weight: 600;
    color: var(--text-color);
    padding-left: 8px;
}

.btn-delete {
    font-size: 14px;
    padding: 5px 2px;
    border: 0px solid #C0C2DE;
    border-radius: 20px;
    width: 85px;
    height: 34px;
    font-weight: 500;
    margin: 5px 10px 10px 0px;
    background-color: #ccc;
    color: #201f1f;
    align-items: center;
}

.plus {
    border: none;
    height: 40px;
    width: 40px;
    background-color: var(--plus-button);
}

.signed-members {
    font-size: 14px;
    padding-left: 3px;
    font-weight: 500;
    color: var(--text-color);
}

.product-back-arrow {
    cursor: pointer;
    font-size: 18px;
    color: var(--text-color);
}

.product-hr {
    width: 98%;
    margin-left: 1%;
    color: var(--hr-line-color);
}

.microsoft-excel {
    height: 20px;
    margin-left: 90px;
}

.search-member {
    margin-left: 20px;
}

.search {
    border: 1px solid #DBDBDB;
    border-radius: 50px;
    height: 34px;
    padding-left: 35px;
    font-size: 14px;
    width: 90%;
    color: #000000;
    box-shadow: 1px 1px 1px 1px #C0C2DE;
    background-color: var(--search-input-color);
}

.category-container {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
}

.search-icon {
    position: absolute;
    top: 20px;
    transform: translatex(80%);
    font-size: 15px;
}

.group_search-icon {
    position: absolute;
    transform: translatex(50%);
    z-index: 999;
    font-size: 15px;
    padding-left: 28px;
}

input:focus {
    outline: none;
}

.filter {
    position: relative;
    bottom: 1px;
    cursor: pointer;
    width: 37px !important;
    height: 38px !important;
    background: var(--plus-button) 0% 0% no-repeat padding-box !important;
    background: var(--plus-button) 0% 0% no-repeat padding-box;
    box-shadow: 6px 6px 12px rgba(105, 105, 105, 0.1607843137);
    border-radius: 12px;
}

.custom-menu {
    background: none;
    border: none;
}

.filter-icon {
    position: absolute;
    top: 9px;
    left: 9px;
}

.plus-icon {
    position: absolute;
    top: 10px;
    left: 12px;
    cursor: pointer;
    color: var(--text-color);
}

.customer-main-1 {
    padding-left: 10px;
}

.headers-position {
    position: absolute;
    top: 0;
    z-index: 999;
}

.filter-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.filter-panel {
    background: #fff;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.subcategory-list {
    list-style-type: none;
    display: flex;
}

.dropdown-icon {
    font-size: 12px;
}

.subcategory-listing {
    overflow: hidden;
    z-index: 1;
    position: fixed;
    list-style-type: none;
    font-size: 14px;
}

.button-category {
    font-size: 12px;
    background-color: transparent;
    border: none;
    border-radius: 9px;
}

@media (min-width:320px) and (max-width:600px) {
    .microsoft-excel {
        margin-left: 0px;
    }

    .search-member {
        margin-left: 10px;
    }

    .filter {
        width: 40px !important;
    }

    .search {
        padding-left: 12px !important;
    }
}

@media (min-width:600px) and (max-width:768px) {
    .microsoft-excel {
        margin-left: 0px;
    }

    .search-member {
        margin-left: 10px;
    }

    .main-customer {
        margin-left: 10px;
        margin-top: 3%;
    }

    .signed-members {
        padding-left: 9%;
    }
}

@media (min-width:990px) and (max-width:1024px) {
    .microsoft-excel {
        margin-left: 0px;
    }

    .search-member {
        margin-left: 0px;
    }
}

.employee-search {
    margin-left: 170px;
}

.employee-list {
    font-size: 13px;
    color: #707070;
}

.emp-logo-image {
    margin-left: -10px;
    margin-top: 10px;
}

@media (min-width:300px) and (max-width:600px) {
    .employee-search {
        margin-left: 30px;
    }
}

@media (min-width:768px) and (max-width:992px) {
    .employee-search {
        margin-left: 100px;
    }
}

@media (min-width:992px) and (max-width:1024px) {
    .employee-search {
        margin-left: -40px;
    }

    .emp-logo-image {
        margin-left: 80px;
    }

    .employee-list {
        padding-left: 82px;
    }

    .category-container {
        width: 80%;
    }

    .product-search {
        width: 100%;
    }

    .signed-members {
        padding-left: 6%;
    }
}

.plus {
    background-color: var(--plus-button);
    border-radius: 13px;
}

.sub-title {
    font-size: 16px;
    color: var(--text-color);
    opacity: 1;
}

.product-search {
    background-color: var(--search-input-color);
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 23px;
    border: none;
    width: 170px;
    height: 40px;
    padding-left: 22%;
    font-size: 15px;
    color: var(--text-color);
}

.category-button {
    width: auto;
    height: auto;
    border-radius: 8px;
    opacity: 1;
    border: none;
    font-weight: 600;
    font-size: 16px;
}

.searching-1 {
    position: relative;
    left: 35px;
    top: 1px;
}

.searching {
    position: absolute;
    top: 14px;
    transform: translatex(-50%);
    z-index: 999;
    padding-left: 7%;
    font-size: 15px;
}

.intent-search {
    margin-right: 10px;
    padding: 8px;
    border-radius: 18px;
    background: #CCCCDB;
}

.intent-button {
    background-color: #C0C2DE;
    color: black;
    padding: 5px;
    border-radius: 25px;
    border: none;
    height: 35px;
    width: 90px;
    padding-top: 8px;
    font-size: 15px;
    cursor: pointer;
}

.intent-title {
    color: var(--text-color);
}

.w-search-icon {
    position: relative;
    left: 10px;
    top: 25px;
    z-index: 999;
}

.quotation-button {
    background-color: #00000029;
    color: black;
    padding: 6px;
    border-radius: 10px;
    border: none;
    font-size: 14px;
}

@media (min-width:1120px) and (max-width:1290px) {
    .search-ware {
        margin-left: 120px;
        margin-bottom: 20px;
    }

    .w-search-icon {
        position: relative;
        left: 12px;
        top: 25px;
    }

    .search-icon {
        padding-left: 0%;
    }
}

@media (min-width:992px) and (max-width:1120px) {
    .search-ware {
        margin-left: 100px
    }
}

@media (min-width:768px) and (max-width:992px) {
    .search-ware {
        margin-left: 0px;
        margin-top: 0px;
    }
}

@media (min-width:1140px) and (max-width:1250px) {}


@media (min-width:1120px) and (max-width:1440px) {
    .category-container {
        width: 80%;
    }
}