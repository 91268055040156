.baap-commerce {
    font-size: 25px;
    color: var(--text-color);
    padding-left: 30px;
    padding-top: 15px;
}

.commerce-line {
    font-size: 14px;
    color: var(--text-color);
    padding-left: 40px;
}

.input-tex {
    padding-top: 150px;
    padding-left: 40px;
}

.input-text {
    margin-left: -20px;
    position: fixed;
    bottom: 0px;
}

.enter-text {
    font-size: 14px;
    color: var(--text-color);
}

.input-number {
    width: 270px;
    height: 34px;
    border: 1px solid var(--text-color);
    background: transparent;
    padding-left: 10px;
    font-size: 14px;
    color: var(--text-color);
}

.note-name {
    color: #454545;
    font-size: 14px;
    padding-top: 17px;
}

.password-text {
    color: var(--text-color);
    font-size: 14px;
}

.name-baap {
    color: var(--text-color);
    font-size: 17px;
}

.all-reserve1 {
    letter-spacing: 0px;
    color:var(--text-color);
    font-size: 12px;
}


.chevron-btn- {
    width: 34px;
    height: 34px;
    border: 1px solid var(--unnamed-color-000000);
    background: #000000;
    border: 1px solid #000000;
    margin-left: 10px;
}

.registration-menu {
    width: 100px;
    background: var(--background-color);
    height: 100vh;
    position: fixed;
}

.registration-padding {
    padding-left: 100px;
    background: var(--secondary-background-color);
    height: 100vh;
}

.toast-notification {
    position: fixed;
    top: 20px;
    right: 50px;
    background-color: #333;
    color: #fff;
    padding: 15px 30px;
    border-radius: 0;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 1000;
}

.toast-notification.active {
    opacity: 1;
}