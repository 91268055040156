:root {
  --background-color: #37364B;
  --secondary-background-color: #303143;
  --tertiary-background-color: #202131;
  --secondary-background: 0px 1px 8px 4px #646465;

  --text-color: #fff;
  --icon-color: #fff;
  --text-color-new: #333;
  --text-color-product: #fff;


  --btn-bg-color: #C0C2DE;
  --btn-background-color: #DFE0F5;

  --thead-bg: #2c2872;

  --sidebar-bg-tooltip: black;
  --sidebar-tooltip-color: white;

  --table-hover: #888aa9;
}