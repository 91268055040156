.file-upload-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hidden {
  display: none;
}

.file-upload-label {
  cursor: pointer;
}

.bordered-box {
  border: 0px dashed #ccc;
  height: 120px;
  width: 100px;
  text-align: center;
  transition: border-color 0.3s ease;
}

.products-label-box {
  border: 1px dashed #ccc;
  height: 150px;
  width: 110px;
  text-align: center;
  transition: border-color 0.3s ease;
}

.icons-label-box {
  border: 1px dashed #ccc;
  height: 105px;
  width: 120px;
  text-align: center;
  transition: border-color 0.3s ease;
}

.uploading-text {
  margin-top: 45%;
}

.bordered-box:hover {
  border-color: #5a6c7f;
}

.avatar {
  border: none !important;
  height: 120px;
  width: 100px;
  margin-left: 0px;
}

.uploaded-icon {
  height: 106px;
  width: 119px;
}

.avatar-file {
  position: relative;
  font-size: 0.7em;
  bottom: 120px;
}

@media (min-width:768px) and (max-width:1024px) {
  .avatar-images {
    margin-left: 80%;
  }
}