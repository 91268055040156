.po-title-style {
    color: var(--text-color);
}

.table-container1 {
    height: calc(112vh - 230px);
    overflow-x: hidden;
    overflow-y: auto;
    margin-right: 0;
    padding-right: 0;
}
.supp-bold{
    font-weight: bold;
}

.table-cell {
    white-space: nowrap;
}

.table>:not(:first-child) {
    border-top: none;
}