.product-detail-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  margin-left: 0px;
  transition: margin-right 0.3s ease;
  background: #00000099;
}

.popup-contents {
  background-color: var(--background-color);
  padding: 20px;
  width: 55%;
  height: 70%;
  max-width: 100%;
  max-height: 100%;
  border-radius: 20px;
  position: fixed;
  transition: left 0.3s ease;
}

.scroler {
  overflow-y: auto;
  max-height: 56vh;
}

.close {
  position: absolute;
  top: 24px;
  right: 30px;
  font-size: 20px;
  cursor: pointer;
  color: var(--icon-color)
}

.more-option {
  background-color: transparent;
  font-size: 12px;
  border: none;
  color: blue;
}

.product-title-name {
  font-size: 21px;
  color: var(--text-color);
}

.popup-category-1 {
  font-size: 18px;
}

.popup-category {
  font-size: 14px;
}

.product-details {
  display: flex;
  width: 95%;
}

.product-image {
  flex: 1;
}

.price-list-table {
  margin-top: 5%;
}

.popup-image {
  height: 400px;
  width: 700px;
}

.popup-name {
  margin-left: 3%;
  font-size: 16px;
}

.product-image img {
  max-width: 75%;
  margin-top: 35px;
  height: auto;
  border-radius: 8px;
}

.buy-price {
  text-decoration: line-through;
  color: var(--text-color);
  font-weight: 500;
  color: gray;
}

.sp-price {
  color: rgb(140, 194, 39);
  font-size: 22px;
}

.tag-btn {
  background-color: #CCCCDB;
  color: black;
  margin-right: 1%;
  margin-left: 2%;
  font-size: 14px;
  border-radius: 4px;
  padding: 5px;
  margin-top: 5px;
}

.popup-tag {
  font-size: 18px;
}

.popup-priceTag1 {
  color: var(--text-color);
  margin-left: -30px;
}

.popup-priceTag {
  color: var(--text-color);
}

.popup-priceTag-new {
  color: var(--color-text);
}

.popup-character-list {
  color: var(--text-color);
  font-size: 16px;
  line-height: 19px;
}

.prices {
  color: var(--text-color);
}

.modals {
  color: #ECECF5;
}

.popup-character {
  font-size: 14px;
}

.product-info {
  flex: 3;
}

.popup-description {
  color: var(--text-color);
}

.delete-button {
  border: 1px solid #CCCCDB;
  background-color: #CCCCDB;
  color: black;
  padding: 4px;
  border-radius: 5px;
  width: 20%;
  margin-top: 10px;
}

.delete-button-d {
  border: 1px solid #CCCCDB;
  background-color: red;
  color: black;
  padding: 4px;
  border-radius: 5px;
  width: 20%;
}

.popup-box-product {
  position: fixed;
  background: #00000099;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  top: 0;
  left: 0;
}

.cust-box-product {
  position: relative;
  width: 24%;
  min-height: 140px;
  background-color: #F0F1FF;
  border-radius: 4px;
  margin: 275px auto;
  padding: 18px;
  padding-left: 30px;
  overflow: auto;
  bottom: 90px;
  font-size: 16px;
  box-shadow: 0 10px 19px rgba(0, 0, 0, 0.5);
  right: 120px;
  transform: translate(145px, 10px);
}

.star {
  margin-right: 337px;
  font-size: 9px;
}

.price {
  color: var(--text-color);
}

.variants-btns {
  border: 1px solid black;
  background-color: #ECECF5;
  color: black;
  padding: 6px;
  border-radius: 12px;
  margin-top: 10px;
}

.property {
  font-size: 18px;
}

.variant-title {
  font-size: 22px;
}

.confirm-yes {
  background-color: #CCCCDB;
  color: black;
  padding: 6px;
  width: 10%;
  border: none;
  border-radius: 4px;
}

.confirm-no {
  background-color: #CCCCDB;
  color: black;
  padding: 6px;
  width: 10%;
  border: none;
  border-radius: 4px;
  margin-left: 5%;
}

.product-image {
  width: 30%;
  padding: 20px;
}

.product-info {
  overflow-y: auto;
}

@media (min-width:768px) and (max-width:1024px) {}