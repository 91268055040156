.config-cardstyle {
    margin-top: 15px;
    margin-left: 3px;
    height: 165px;
    width: 99%;
    background: var(--card-bg) 0% 0% no-repeat padding-box;
    color: var(--text-color);
    box-shadow: 0px 1px 8px 4px #dcdded;
    border-radius: 5px;
    opacity: 1;
    background-color: var(--background-color);
    border: none;
}

.add-config-inputs {
    background-color: transparent;
    border: 1px solid #C0C2DE;
    opacity: 1;
    height: 35px;
    outline: none;
    border-radius: 5px;
    color: var(--text-color);
    width: 100%;
}

.config-scroll {
    height: calc(100vh - 100px);
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 0;
    padding-right: 0;
    scrollbar-color: transparent transparent;
}

.error-border {
    border: 1px solid red;
}

.config-validation {
    position: relative;
    right: 7px;
}

.add-config-btn {
    position: absolute;
    right: 30px;
    top: 27px;
}