.lang-style {
  position: absolute;
  bottom: 20px;
  left: 4.2%;
}

.wrapper::-webkit-scrollbar {
  width: 5px;
  background-color: rgb(202, 201, 201);
  background-color: transparent;
}

.wrapper::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

.wrapper {
  height: calc(100vh - 120px);
  overflow-x: hidden;
  overflow-y: auto;
  margin-right: 0;
}

select {
  border: none;
  outline: none;
  background-color: transparent;
}

.container-container {
  display: flex;
  width: 100%;
  transition: margin-left 0.3s;
  overflow: hidden;
  padding: 0px;
}

.footer-icon11 {
  height: 20px;
  outline: none;
  width: 20px;
  cursor: pointer;
}

.footer-icon {
  height: 20px;
  outline: none;
  width: 18px;
}

.sidebar {
  width: 60px;
  height: 100vh;
  background-color: var(--background-color);
  padding-top: 60px;
  transition: width 0.3s;
}

.sidebar.open {
  width: 255px;
  height: 100vh;
}

.toggle-button {
  position: absolute;
  top: 12px;
  left: 18px;
  cursor: pointer;
  color: black;
  font-size: 20px;
  z-index: 1;
  border: none;
  background: transparent;
}

.text-decoration {
  text-decoration: none;
  color: var(--text-color);
}

.text-decoration:hover {
  text-decoration: none;
  color: var(--text-color);
}

.list-unstyled {
  list-style-type: none;
  padding: 0;
  list-style: none;
  text-decoration: none !important;
  color: var(--text-color);
  margin: 0;
}

.list-item {
  padding: 5px 17px;
  color: var(--text-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  display: inline-block;
  margin-right: 16px;
}

.active {
  font-weight: bold;
}

.footer-icon1 {
  height: 18px;
  outline: none;
  margin-left: 20px;
}

.sidebar.open li span {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  transition: margin-left 0.3s;
}

.table-head-exucation {
  color: var(--text-color);
}

.select-lang {
  display: block;
  background-color: var(--background-color);
  color: var(--text-color);
  position: relative;
  right: 6px;
}

.select-lang:hover {
  background-color: #F0F1FF;
  color: black;
  font-weight: 900;
}

.select-language {
  background-color: var(--secondary-background-color);
  color: var(--text-color);
  padding: 5px;
  margin-bottom: 15px;
}

.tooltip-style {
  background: black !important;
  color: white;
  z-index: 9999;
}

.crm-style {
  color: var(--text-color) !important;
  font-weight: 800;
  font-size: 18px;
  padding-left: 15px;
}

.crm-menu {
  font-size: 14px;
  color: #333;
}

.font16 {
  font-size: 16px;
  font-family: 'Baloo 2', sans-serif;
  color: var(--text-color);
}

.role-ico {
  padding-left: 22px;
}

.list-item.selected {
  background-color: #d5dde6;
  color: white;
  font-weight: bold;
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80px;
  margin-bottom: -8px;
}

.mb10 {
  margin-bottom: 20px !important;
}

.close-sidebar {
  position: fixed;
  left: 210px;
  top: 20px;
}

.group-name1 {
  position: fixed;
  top: 18px;
}

.group-name {
  font-size: 16px;
  font-weight: 800;
  color: var(--text-color);
}