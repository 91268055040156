.upload-arrow {
    cursor: pointer;
    display: inline-block;
    margin-right: 10px;
}

.quatation-sidebar-update {
    top: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    width: 65%;
    background-color: var(--background-color);
    color: var(--text-color);
    z-index: 999;
}

.sidebar-wrapper-quotation {
    height: calc(100vh - 146px);
    overflow-x: hidden;
    overflow-y: auto;
    margin-right: 0;
    padding-right: 0;
}

.font900 {
    font-weight: 900;
}

.font14 {
    font-size: 14px;
}

.font18 {
    font-size: 18px;
}

.lineHeight {
    line-height: 4px;
}

.container_ {
    max-width: 20ch;
    word-wrap: break-word;
    line-height: 15px;
}

.view-button-q {
    background-color: #C0C2DE;
    color: black;
    border: none;
    cursor: pointer;
    width: 60px;
    height: 25px;
    position: relative;
    bottom: 30px;
    margin-left: 26%;
    border-radius: 20px;

}

.upload-arrow {
    cursor: pointer;
    display: inline-block;
    margin-left: 15px;
}

.view-button {
    background-color: #C0C2DE;
    color: black;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.footer-btn-container {
    justify-content: flex-start;
}

.price-input {
    text-align: center;
    width: 60px;
    border-radius: 5px;
    border: 1px solid #C0C2DE;
    background-color: transparent;
    color: var(--text-color);
}


.add-inve {
    border: 1px solid #C0C2DE;
    background: transparent;
    border-radius: 5px;
    height: 27px;
    width: 70px;
    background-color: #C0C2DE;
}

.add-inve:hover {
    background-color: #a3a7dd;
}

.quotation-text {
    color: var(--text-color);
}

.table-input {
    color: var(--text-color);

}