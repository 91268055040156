.close-icon {
    position: absolute;
    top: 17px;
    right: 22px;
    cursor: pointer;
    font-size: 20px;
    color: var(--icon-color);
}

.hr-line {
    margin-left: 25px;
    width: 95%;
    margin-top: -2px
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
}

.warehouse-search {
    background-color: var(--search-input-color);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
    border-radius: 23px;
    border: none;
    width: 210px;
    height: 40px;
    padding-left: 20%;
    font-size: 15px;
    margin-top: -10px;
}

.grid-item {
    display: flex;
    flex-direction: column;
}

.grid-item img {
    object-fit: cover;
    width: 145px;
    height: 160px;
    border-radius: 6px;
}

.feature-line {
    width: 96%;
    margin-left: 10px;
}

.warehouse-capacity {
    font-size: 14px;
    color: var(--text-color);
    line-height: 1px;
}

.warehouse-name {
    font-size: 16px;
    color: var(--text-color);
    font-weight: 600;
}

.warehouse-location {
    font-size: 14px;
    color: var(--text-color);
}

.pin-code {
    color: var(--text-color);
}

.Manager-input {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #C0C2DE;
    width: 102%;
    font-size: 14px;
    background: transparent;
}

.warehouse-list-scroll {
    height: 67vh;
    overflow-y: auto;
}

.ware-trash {
    cursor: pointer;
    color: var(--text-color);
}

.delete {
    height: 15px;
    cursor: pointer !important;
}

.pagination-btn {
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    position: fixed;
    bottom: 10px;
}

.feature-pagination {
    border: none;
    background-color: black;
    color: white;
    border-radius: 3px;
    font-size: 14px;
    height: 30px;
}

.sidebar-update {
    top: 0%;
    right: 0;
    bottom: 0;
    height: 100vh;
    z-index: 9999;
    position: fixed;
    width: 30%;
    background-color: var(--background-color);
    overflow: hidden;
}

.warehouse-horizental {
    overflow-x: hidden;
}

hr.wareside1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 2px solid;
    color: var(--hr-line-color);
}

@media (min-width:992px) and (max-width:1120px) {
    .grid-item img {
        width: 90px;
        height: 110px;
    }

    .warehouse-capacity {
        font-size: 10px;
    }

    .warehouse-name {
        font-size: 12px;
    }

    .warehouse-location {
        font-size: 10px;
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px;
    }
}

@media (min-width:320px) and (max-width:600px) {
    .grid-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px;
    }
}

@media (min-width:768px) and (max-width:992px) {
    .grid-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 15px;
    }
}


@media(min-width:300px) and (max-width:600px) {}

@media (min-width:768px) and (max-width:992px) {}

.edit-warehouse {
    position: relative;
    left: 5vh;
    color: var(--text-color);
    cursor: pointer;
}

.delete-warehouse {
    position: relative;
    right: 5px;
    cursor: pointer;
}

@media(min-width:1100px)and (max-width:1413px) {
    .grid-container {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 8px;
    }
}

.hr-line-warehouse {
    position: relative;
    bottom: 10px;
    margin-left: 22px;
    margin-top: 3px;
    width: 96%;
    color: var(--hr-line-color);
}