.profile-section {
  display: flex;
  align-items: center;
}

.main-padding-customer {
  padding-top: 19px;
  padding-left: 30px;
  padding-right: 30px;
}

.profile {
  object-fit: cover;
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.hr-line-cust {
  position: relative;
  bottom: 27px;
}

.hr-line-customer {
  position: relative;
  bottom: 21px;
}

.customer_xmark {
  position: relative;
  bottom: 9px;
  font-size: 20px;
  left: 96%;
  color: var(--icon-color) !important;
}

.info {
  flex-grow: 1;
  margin-left: 20px;
}

.customer_email {
  position: relative;
  bottom: 3px;
  color: var(--icon-color) !important;
}

.name {
  height: 17px;
  text-align: left;
  font: normal normal 900 24px/61px Kumbh Sans;
  letter-spacing: 0px;
  color: var(--text-color);
  opacity: 1;
}

.premium-btn {
  border: none;
  background-color: #baeaa8;
  border-radius: 15px;
  height: 25px;
  width: 85px;
  font-size: small;
  position: relative;
  bottom: 20px
}

.color-text {
  color: #747474;
  position: relative;
  bottom: 13px;
  color: var(--icon-color) !important;
}

.customer-email {
  position: relative;
  bottom: 18px;
}
.phone-email{
   position: relative;
   bottom: 12px;
}

.buttons-section {
  display: flex;
  justify-content: flex-start;
  position: relative;
  bottom: 14px;
  font-size: 12px;
}

.cust-info-btn {
  background-color: #DFE0F7;
  color: black;
  border: none;
 
}

.cust-info-btn1 {
  background-color: #DFE0F7;
  color: black;
  border: none;
  position: fixed;
  right: 15px;
  
}

.order-service-btn {
  background-color: #dfe0f7;
  border: none;
}

.close-button {
  position: absolute;
  right: 30px;
  background-color: #c0c2de;
  border: none;
  height: 30px;
  width: 84px;
  border-radius: 21px;
}

.selected {
  background-color: #a2ce0d;
}

.btn_disable1 {
  font-size: 14px;
  padding: 5px 2px;
  border: 0px solid #C0C2DE;
  border-radius: 20px;
  width: 80px;
  font-weight: 500;
  margin: 5px 10px 10px 0px;
  background-color: #ccc;
  color: #201f1f;
  margin-left: 70%;
  align-items: center;
}

/* CustomerDetails.scss */

/* Container for the filter button */
.filter-button-container {
  display: flex;
  justify-content: flex-end; /* Aligns the button to the right */
  margin-top: 10px; /* Space above the button */
  margin-bottom: 10px; /* Space below the button */
}

/* Filter button styling */
.filter-btn {
  background-color: #000000; /* Button background color */
  color: #ffffff; /* Button text color */
  padding: 10px 20px; /* Button padding */
  border: none; /* Remove border */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

/* Button hover effect */
.filter-btn:hover {
  background-color: #000000; /* Darker shade on hover */
}

/* Button active state */
.filter-btn:active {
  background-color: #000000; /* Even darker shade when clicked */
}


@media (min-width: 1200px) and (max-width: 1365px) {
  .customer_xmark {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .name {
    font: normal normal 900 24px/29px Kumbh Sans;
  }

  .customer_address {
    position: relative;
    bottom: 3px;
  }

  .color-text {
    position: relative;
    bottom: 4px;
  }

  .customer-email {
    position: relative;
    bottom: 8px;
  }
}

.main {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.body_container {
  flex: 1;
  overflow: hidden;
}

.main-padding-customer {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}