.terms_icon {
    position: absolute;
    top: 11px;
    padding-left: 2px;
    transform: translatex(80%);
    font-size: 14px;
}

.search_terms {
    border: 1px solid #DBDBDB;
    border-radius: 50px;
    height: 34px;
    padding-left: 35px;
    font-size: 14px;
    width: 80%;
    color: #000000;
    box-shadow: 1px 1px 1px 1px #C0C2DE;
    background-color: var(--search-input-color);
}

.scroll-terms{
    max-height: 70vh;
    overflow-y: scroll;
    // padding: 10px;
}

/* TermsCondition.scss */

.disabled-text {
    color: gray;
    cursor: not-allowed;
}

.enabled-text {
    color: black;
    cursor: pointer;
}

button:disabled {
    color: gray;
    border: none;
}

.terms_delete {
    font-size: 14px;
    padding: 5px 2px;
    border: 0px solid #c0c2de;
    border-radius: 20px;
    width: 80px;
    font-weight: 500;
    background-color: #ccc;
    color: black;
    align-items: center;
    height: 33px;
}

.terms-button {
    background-color: #C0C2DE;
    color: black;
    padding: 5px;
    border-radius: 25px;
    border: none;
    height: 34px;
    width: 80px;
    padding-top: 7px;
    font-size: 15px;
    cursor: pointer;
}

.term-flex {
    display: flex;
    justify-content: space-between;
    color: var(--text-color);

}

.popup_flex {
    display: flex;
    justify-content: space-between;
    color: var(--text-color);

}

.terms-code {
    color: var(--text-color);

}

.close_popup {
    position: relative;
    top: 5px;
    left: 6px;
    font-size: 20px;
}

.link-button {
    color: #007bff;
    text-decoration: none;
    cursor: pointer;
}

.link-button:hover {
    text-decoration: underline;
}

.clickable {
    color: #007bff;
    cursor: pointer;
}

.clickable:hover {
    text-decoration: underline;
}

.cancel-term {
    margin-left: 5px;
    font-size: 18px;
}

.term-detail-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    margin-left: 0px;
    transition: margin-right 0.3s ease;
    background: #00000099;
}

.popup_term {
    background-color: var(--background-color);
    padding: 20px;
    max-width: 60%;
    max-height: 100%;
    border-radius: 20px;
    position: fixed;
    transition: left 0.3s ease;
    color: var(--text-color);

}

.term-center {
    position: relative;
    top: 50px;
    left: 35%;
}

.term-code-hr {
    color: var(--hr-line-color);
}

.popup_content {
    position: relative;
    width: 305px;
    min-height: 140px;
    background-color: #F0F1FF;
    border-radius: 4px;
    margin: 244px auto;
    padding: 18px;
    padding-left: 30px;
    font-size: 16px;
    overflow: auto;
    bottom: 50px;
    transform: translate(56px, 10px);
}

.terms-nodelete {
    cursor: pointer;
    height: 33px;
    width: 55px;
    transform: translate(40px, 10px);
    margin-left: 10px;
    color: #333;
    border: 1px solid gray;
    background-color: transparent;
}

.terms-delete {
    cursor: pointer;
    height: 33px;
    width: 55px;
    color: #ffffff;
    background-color: #000000;
    border: none;
    transform: translate(40px, 10px);
    margin-left: 20px;
}

.term-popup-box {
    position: fixed;
    background: rgb(0 0 0 / 46%);
    width: 100%;
    height: 100vh;
    z-index: 9999;
    top: 0;
    left: 0;
}

//pagination css
.pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination-bar {
    display: flex;
    list-style: none;
    padding: 0;
}

.pagination-bar li {
    margin: 0 5px;
}

.pagination-bar li a {
    padding: 3px 10px;
    border: 1px solid #ccc;
    color: #319865;
    cursor: pointer;
    text-decoration: none;
}

.pagination-bar li.active a {
    background-color: #319865;
    color: white;
}

.pagination-bar li a:focus {
    outline: none;
}