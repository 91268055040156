.dashboard-header {
    display: flex !important;
    justify-content: space-between !important;
}

.date-title-dash {
    margin-left: 76%;
    position: relative;
    top: 8px;
}

.dash-scroll {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(65vh - 100px);
    position: relative;
    bottom: 20px;
}

.date-dash {
    background-color: transparent;
    height: 24px;
    width: 245px;
    margin-left: 5%;
}

.set-graph {
    display: flex;
    justify-content: center;
}

.date-set-line {
    position: relative;
    right: 71px;
}

.ser-request {
    color: #F06D6D;
}

.start-date-dash {
    position: relative;
    left: 8px;
    height: 34px;
    width: 95%;
    background: transparent;
    outline: none;
    color: var(--text-color);
    border: 1px solid #C0C2DE;
    border-radius: 5px;
}

.scrollable-content {
    height: calc(100vh - 90px);
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 0;
    padding-right: 0;
    scrollbar-color: transparent transparent;
}

.top-cust-box {
    margin-top: 15px;
    background: var(--card-bg) 0% 0% no-repeat padding-box;
    color: var(--text-color);
    box-shadow: 0px 1px 8px 4px #dcdded;
    border-radius: 5px;
    opacity: 1;
    background-color: var(--background-color);
    border: none;
    padding: 14px 15px !important;
    height: auto;
    width: 99%;
}

.end-date-dash {
    position: relative;
    left: 10px;
    height: 34px;
    width: 95%;
    background: transparent;
    outline: none;
    color: var(--text-color);
    border: 1px solid #C0C2DE;
    border-radius: 5px;
}

.order-dash-cardstyle {
    margin-top: 15px;
    background: var(--card-bg) 0% 0% no-repeat padding-box;
    color: var(--text-color);
    box-shadow: 0px 1px 8px 4px #dcdded;
    border-radius: 5px;
    opacity: 1;
    background-color: var(--background-color);
    border: none;
    padding: 14px 15px !important;
    height: 400px;
    width: 99%;
}

.order-statistics-dash {
    margin-top: 15px;
    width: 136px !important;
    background: var(--card-bg) 0% 0% no-repeat padding-box;
    color: var(--text-color);
    box-shadow: 0px -1px 2px 1px #dcdded;
    border-radius: 5px;
    opacity: 1;
    background-color: var(--background-color);
    border: none;
    height: 73px;
    margin-left: 21px;
}