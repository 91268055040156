.add-address-sidebar {
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  position: fixed;
  width: 50%;
  background-color: var(--text-color);
  color: var(--text-color);
  overflow: auto;
  z-index: 1111;
}

.add-customer-address {
  margin-top: 10px;
}

.radi {
  float: left;
}

.add-Address-inputs {
  background-color: transparent;
  border: 1px solid #C0C2DE;
  opacity: 1;
  height: 35px;
  outline: none;
  bottom: 19px;
  width: 100%;
  border-radius: 5px;
  color: var(--text-color);
}

.City-label {
  padding-bottom: 11px;
}

.country {
  padding-bottom: 11px;
  margin-right: 220px;
}

.Zip1-label {
  padding-bottom: 11px;
  margin-left: -15px;
  margin-right: 247px;
}

.saveDraft-btn1 {
  border-radius: 15px;
  font-size: 12px;
  width: 78px;
  height: 36px;
  border: none;
  outline: none;
  background-color: #C0C2DE;
}

.saveDraft-btn2 {
  width: 83px;
  background-color: #a0a2be;
  border-radius: 22px;
  border: none;
  height: 37px;
}

.Car {
  margin-right: 360px;
}

.cust-title {
  text-align: left !important;
}

.BillingShiping {
  text-align: left;
}

.col-6 {
  text-align: left;
}

.ButoonStyle {
  margin-left: -7px;
  text-align: left;
}