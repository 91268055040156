.feature-property {
    border: 1px solid #C0C2DE;
    opacity: 1;
    outline: none;
    padding-left: 1%;
    background-color: transparent;
    height: 34px;
    font-size: 14px;
    margin-left: 0.5%;
}

.feature-value {
    border: 1px solid #C0C2DE;
    opacity: 1;
    outline: none;
    margin-left: 1%;
    background-color: transparent;
    padding-left: 3%;
    width: 20%;
    height: 34px;
    font-size: 14px;
}

.feature-description {
    border: 1px solid #C0C2DE;
    opacity: 1;
    outline: none;
    margin-left: 1%;
    width: 33%;
    background-color: transparent;
    padding-left: 2%;
    height: 34px;
    font-size: 14px;
}

@media (min-width: 1200px) {
    .feature-description {
        width: 40%;

    }

}

.feature-add-property {
    border: 1px solid #c0c2de;
    opacity: 1;
    outline: none;
    background-color: #c0c2de;
    color: black;
    height: 33px;
    width: 13%;
    margin-left: 2%;
}

.variants-section {
    margin-left: 15px;
}

.price-inputs {
    // border: 1px solid var(--unnamed-color-000000);
    border: 1px solid #C0C2DE;
    border-radius: 5px;
    opacity: 1;
    outline: none;
    width: 50%;
    background-color: transparent;
    height: 32px;
    padding-left: 3%;
    color: var(--text-color);
}

.feature-save {
    background-color: #c0c2de;
    border: none;
    font-size: 14px;
    width: 90px;
    border-radius: 5px;
    height: 35px;
    outline: none;
    color: var(--text-color);
}

.feature-delete-button {
    background-color: black;
    color: white;
    border: 1px solid;
    width: 100%;
    padding: 4px;
}

.feature-select {
    // border: 1px solid var(--unnamed-color-000000);
    border: 1px solid #C0C2DE;
    opacity: 1;
    width: 90%;
    padding: 1px;
    outline: none;
    padding: 4px;
    border-radius: 5px;
}

@media (min-width:769px)and (max-width:1024px) {
    .feature-description {
        width: 20%;
    }

    .feature-delete-button {
        width: 100%;
    }

    td {
        width: 100%;
    }

    .feature-add-property {
        width: auto;
    }
}

@media (min-width:425px)and (max-width:768px) {
    .feature-description {
        width: auto;
    }

    .feature-property {
        width: 20%;
    }

    .feature-delete-button {
        width: 100%;
    }

    td {
        width: 5%;
    }

}

@media(min-width:900px)and (max-width:1200px) {
    .feature-description {
        width: 40%;
    }

    .feature-delete-button {
        width: auto;
        height: auto;
    }

    td {
        width: 5%;

    }

    .price-inputs {
        width: 80%;
        font-size: 14px;
    }

    .feature-delete-button {
        width: 100%;
    }

    .feature-add-property {
        width: auto;
    }

    .variants-section {
        margin-left: 15px;
    }
}