.confirmation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .confirmation-popup {
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    padding: 20px;
    max-width: 300px;
  }
  
  .confirmation-popup p {
    margin-bottom: 10px;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
  }
  
  .confirmation-buttons button {
    margin: 0 10px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .confirmation-buttons button:first-child {
    background-color: #000000;
    color: #ffffff;
  }
  
  .confirmation-buttons button:last-child {
    background-color: transparent;
    color: #000000;
    border: 1px solid gray;
  }
  