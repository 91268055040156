.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 9999; 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup {
    background-color: white;
    padding: 20px 45px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
  }
  
  .stock-popup-icon{
    font-size: 16px;
     margin-left: 20px;
  
  }

  .cursor-pointer{
    cursor: pointer;
  }
 
  .cancel-icon {
    cursor: pointer;
    font-size: 40px;
    color: #555;
    float: right;

  }
  .send-alert-text{
    font-size: 14px;
  }