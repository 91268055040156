.cus-info {
  font-weight: bold;
}

.card-customer-info {
  width: 100%;
  color: var(--text-color);
}

.font14 {
  font-size: 14px;
}

.empl-date-picker {
  width: 57vh !important;
  color: black;
}

.card1 {
  margin-top: 10px;
  min-height: 256px;
  background: var(--card-bg) 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 8px 4px #DCDDED;
  border-radius: 5px;
  border: 1px solid #e0e1fb;
  opacity: 1;
}

.cust-title-info {
  font-size: 20px !important;
}

.info-icon {
  color: #333;
  margin-left: 3vh;
  width: 20px;
  height: 20px;
  position: relative;
  bottom: 4px;
  color: var(--icon-color);
}

.line-height-add {
  line-height: 13px;
}

.email-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
}

.bank-label {
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
}

@media (min-width: 1200px) and (max-width: 1365px) {
  .card-customer-info {
    width: 100%;
  }
}

.height-line {
  line-height: 0;
  color: var(--text-color) !important;
}

.line-height-add {
  line-height: 12px;
  color: var(--text-color) !important;
}

.line-height-add1 {
  line-height: 22px;
}