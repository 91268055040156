.setting {
    font-size: 18px;
    padding-top: 20px;
    padding-left: 6px;
}

.update-setting {
    padding-left: 12px;
    font-weight: 500;
    font-size: 14px;
    color: var(--text-color);
}

.set-height {
    line-height: 10px;
}

.delivery-code {
    padding-left: 40px;
}

.delivery-title {
    font-size: 18px;
    color: var(--text-color);
    padding-left: 3px;
}

.location-icon1 {
    color: var(--icon-color) !important;
}

.feature-delivery {
    font-size: 14px;
    color: var(--icon-color) !important;
}

.scrollbar-delivery {
    height: calc(100vh - 180px);
    overflow-x: hidden;
    overflow-y: auto;
}

.add-code {
    float: right;
    margin-right: 2%;
    margin-top: 14px;
    border: none;
    border-radius: 20px;
    border: 1px solid #E7E8FA;
    background-color: #C0C2DE;
    padding: 6px;
    font-size: 14px;
    cursor: pointer;
    width: 80px;
}

.feature-tag {
    height: 41px;
    width: 102px;
    background-color: #87F2DF;
    font-size: 16px;
    padding-top: 9px;
    cursor: pointer;
    padding-left: 9px;
}

.delivery-hr {
    border: 1px solid gray;
    width: 96%;
    margin-left: 17px;
}

.circle-badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    background-color: #33B29C;
    border-radius: 50%;
    color: #fff;
    font-weight: bold;
}

.margin-right-10 {
    margin-left: 10px;
}

.code-number {
    border-radius: 48%;
    background-color: #33B29C;
    padding: 6px;
    position: relative;
    bottom: 3px;
    margin-left: 20%;
    color: white;
    width: 190px !important;
}

.setting-sidebar {
    top: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    float: right;
    z-index: 999;
    position: fixed;
    width: 30%;
    background-color: var(--background-color);
    color: var(--text-color);
    overflow: hidden;
}

.cancel-delivery {
    color: var(--text-color) !important;
}

.delivery-code {
    padding-left: 6%;
    color: red;
}

.hr-set {
    margin-left: 8%;
    width: 77%;
}

.hr-set-1 {
    margin-left: 5%;
    width: 88%;
}

.code-input {
    border: 1px solid #C0C2DE;
    width: 88%;
    background-color: transparent;
    color: var(--text-color);
    margin-left: 6%;
    border-radius: 5px;
    padding-left: 10px;
    height: 35px;
    font-size: 14px;
}

.cross-mark {
    font-size: 19px;
    position: absolute;
    cursor: pointer;
    right: 22px;
    color: var(--icon-color);
}

.village-name {
    color: var(--text-color);
}

.circle-arrow1 {
    color: var(--icon-color);
}

.village-input {
    border: 1px solid #C0C2DE;
    width: 74%;
    background-color: transparent;
    color: var(--text-color);
    margin-left: 6%;
    border-radius: 5px;
    padding-left: 10px;
    height: 34px;
    font-size: 14px;
}

.location-ico1 {
    color: rgb(77, 68, 68);
}

.code-add-btn {
    background-color: #C0C2DE;
    color: black;
    padding: 5px;
    width: 58px;
    margin-left: 5px;
    border: none;
    border-radius: 4px;
}

.xmark-ico {
    cursor: pointer;
    margin-left: 120px;
    font-size: 20px;
}

.button-savedelivery {
    margin-left: 24px;
    position: relative;
    bottom: 7px;
}

.button-savedelivery1 {
    margin-left: 24px;
}

.save-delivery {
    border: none;
    background: #C0C2DE !important;
    color: black;
    height: 34px;
    border-radius: 18px;
    width: 24%;
}

.cancel-delivery {
    color: black;
    height: 34px;
    border: 1px solid #C0C2DE !important;
    background: transparent;
    border-radius: 22px;
    width: 24%;
}

.fa-circle-xmark {
    cursor: pointer;
}

.hr_height {
    margin-left: 16px;
}

.container-grid {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
}


@media (min-width:320px) and (max-width:600px) {
    .setting-sidebar {
        width: 100% !important;
    }
}

@media (min-width:600px) and (max-width:768px) {
    .setting-sidebar {
        width: 40% !important;
    }
}

@media (min-width:1100px) and (max-width:1213px) {
    small {
        font-size: 10px;
    }
}

.scrollbar-delivery::-webkit-scrollbar {
    width: 5px;
    background-color: rgb(202, 201, 201);
    background-color: transparent;
}

.scrollbar-delivery::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
}

.hr-line-delivery {
    position: relative;
    bottom: 10px;
    margin-left: 16px;
    width: 97%;
    color: var(--hr-line-color);
}