.Address {
    font-size: 17px;
    padding-top: 3px;
    font-weight: 500;
    color: var(--text-color);
}

.text-color1{
    color: var(--text-color);
}

.close-icon-2{
    position: absolute;
    top: 17px;
    padding-left: 115%;
    border: none;
    cursor: pointer;
    background-color: transparent;
    font-size: 20px;
}

.inputes {
    width: 369px;
    height: 35px;
}

.edit-profile{
    cursor: pointer;
}

.inputes1 {
    padding: 8px;
    border-radius: 5px !important;
    border: 1px solid #C0C2DE;
    width: 94%;
    font-size: 14px;
    background: transparent; 
    color: var(--text-color);
}
.emp-text-supplier{
    color: var(--text-color);

}