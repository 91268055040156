.request-sidebar {
    top: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    width: 42%;
    background-color: var(--background-color);
    color: var(--text-color);
    z-index: 999;
}

.order-sidebar-update {
    top: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    width: 59%;
    background-color: var(--background-color);
    color: var(--text-color);
    z-index: 999;
}

.red-box {
    color: red;
    font-size: 13px;
}

.date-picker-wrapper {
    width: 311px;
}

.side-padding {
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
}

.service-request {
    text-align: left;
    line-height: 10px;
}

.date-time {
    text-align: right;
    line-height: 10px;
}

.date-time-text {
    color: #C4C6E1;
}

.sr-hr {
    margin-top: 10px;
}

.line-height {
    line-height: 10px;
}

.textPurple {
    color: #AA1FDD;
}

.textBlue {
    color: #1F68DD;
}

.textOrange {
    color: #FE8002;
}

.textGreen {
    color: #2FCE5B;
}

.textSkyBlue {
    color: #0BB7CE;
}

.sidebar-wrapper-os {
    height: calc(100vh - 244px);
    overflow-x: hidden;
    overflow-y: auto;
    margin-right: 0;
    padding-right: 0;
}

.sidebar-wrapper-os::-webkit-scrollbar {
    width: 5px;
    background-color: transparent !important;
}

.sidebar-wrapper-os::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
}

.saveDraft-btn,
.cancel-btn {
    border-radius: 15px;
    font-size: 12px;
    width: 100px;
    height: 30px;
    border: none;
    outline: none;
    color: black;
}

.cancel-btn {
    background-color: transparent;
    border: 1px solid grey;
    color: var(--text-color);
}

.saveDraft-btn {
    background-color: #C0C2DE;
}

.file-icon {
    font-size: 15px;
    padding-top: 5px;
}

.date-picker1 {
    border: none;
    outline: none;
    background: transparent;
    padding-left: 60%;
}

.textarea-style {
    background: transparent;
    padding: 10px;
    border: 1px solid #C0C2DE;
    outline: none;
}

.upload-input {
    border: none;
}

.input-style1 {
    height: 35px;
    background: transparent;
    border: none;
    border: 1px solid #C0C2DE;
    color: var(--text-color) !important;
    outline: none !important;
    width: 100% auto;
    padding-left: 10px;
}

.totalReturnAmt {
    color: #FE8002;
}

.date-picker-style {
    width: 240px;
}

textarea {
    resize: none;
    height: 55px;
    width: 100%;
}

.buttonStyle {
    margin-top: -15px;
}

.view-doc {
    text-decoration: none;
}

.view-doc:hover {
    color: #C0C2DE !important;
    text-decoration: none;
}

.btn-online-paid {
    width: 82px;
    height: 26px;
    background: #96007b 0% 0% no-repeat padding-box;
    border-radius: 14px;
    opacity: 1;
    color: white;
    border: none;
    font-size: 12px;
}

.text-dark {
    color: var(--text-color);
}

.amount-font {
    font-size: 15px;
}

.rem-amt {
    display: inline-block;
    padding-left: 10px;
}

.btn-paid {
    width: 82px;
    height: 26px;
    background: #1bcb4a 0% 0% no-repeat padding-box;
    border-radius: 14px;
    opacity: 1;
    color: white;
    border: none;
    font-size: 12px;
}

.btn-unpaid {
    width: 62px;
    height: 26px;
    background: #FF0000 0% 0% no-repeat padding-box;
    border-radius: 14px;
    opacity: 1;
    color: white;
    border: none;
    font-size: 13px;
}

.btn-partialy-paid {
    width: 82px;
    height: 26px;
    background: #FE6F5E 0% 0% no-repeat padding-box;
    border-radius: 14px;
    opacity: 1;
    color: white;
    border: none;
    font-size: 12px;
}

.order-status-btn {
    padding: 0px 15px;
    height: 26px;
    background: #ff9f0f;
    border-radius: 14px;
    color: white;
    opacity: 1;
    border: none;
    font-size: 12px;
}

.input-style {
    height: 33px;
    background: transparent;
    border: 1px solid #C0C2DE;
    color: var(--text-color) !important;
    outline: none !important;
    width: 95%;
    padding-left: 10px;
    border-radius: 4px;
}

.p-table {
    width: 100%;
    border-collapse: collapse;
    position: relative;
}

.table-container {
    overflow-x: auto;
}

.Premium {
    width: 67px;
    height: 25px;
    background: #C4A8EA 0% 0% no-repeat padding-box;
    border-radius: 14px;
    opacity: 1;
    font-size: 14px;
    border: none;
    color: black;
}

.Items {
    font-size: 12px;
    color: #807f7f;
}

.tax {
    position: relative;
    left: 99px;
    font-size: 12px;
    color: #807f7f;
}

.RS {
    color: var(--text-color) !important;
}

// .classdate {
//     color: var(--text-color) !important;
// }

.fa-indian-rupee-sign {
    font-size: 12px;
}

.Qty {
    color: #333 !important;
    position: relative;
    left: 43px;
    font-size: 14px;
    color: #807f7f;
}

.pay_det {
    height: 20px;
    text-align: left;
    letter-spacing: 0px;
    opacity: 1;
}

.select-status-p {
    font-size: 14px;
    background-color: transparent;
    height: 34px;
    width: 93%;
    border: 1px solid #C0C2DE;
    position: relative;
    cursor: pointer;
    outline: none;
    padding-left: 10px;
    color: #333;
    border-radius: 5px;
}

.select-status {
    font-size: 14px;
    background-color: transparent;
    height: 34px;
    width: 96%;
    border: 1px solid #C0C2DE;
    position: relative;
    cursor: pointer;
    outline: none;
    padding-left: 10px;
    color: #333;
}

.select-status1 {
    font-size: 14px;
    background-color: transparent;
    height: 34px;
    width: 205%;
    border-radius: 5px;
    border: 1px solid #C0C2DE;
    position: relative;
    cursor: pointer;
    outline: none;
    padding-left: 10px;
    color: #333;
}

.select-status2 {
    font-size: 14px;
    background-color: transparent;
    height: 34px;
    width: 270px;
    border-radius: 5px;
    border: 1px solid #C0C2DE;
    position: relative;
    cursor: pointer;
    outline: none;
    padding-left: 10px;
    color: var(--text-color);
}

.classfont_size {
    letter-spacing: 0px;
    font-size: 12px;
    font-weight: 400;
}

.color {
    color: rgb(146, 144, 144);
}

.total_a_sec label {
    font-size: 12px;
}

.Rate {
    position: relative;
    left: 54px;
    font-size: 16px !important;
    color: #807f7f;
}

.Total {
    position: relative;
    left: 77px;
    font-size: 14px;
    color: #807f7f;
}

.Tobias {
    font-size: 14px;
    color: var(--text-color) !important;
}

.Linus {
    font-size: 14px;
    color: var(--text-color) !important;
}

.Titlo {
    font-size: 14px;
    color: var(--text-color) !important;
}

a {
    color: var(--text-color);
    text-decoration: none;
}

a:hover {
    color: var(--text-color);
    text-decoration: none;
}

.paid {
    color: #3eb460;
}

.Remaining {
    color: red;
    width: 191px;
}

.Balance {
    margin-top: 10px;
    font-size: 16px;
    color: #ee5e5e;
    height: 30px;
    margin-bottom: -10px;
}

.error-message {
    color: red;
}

.payment-btn {
    position: relative;
    max-width: 50px;
    margin-left: 58px;
}

.update-class-container {
    justify-content: flex-start;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .font14 {
        font-size: 12px !important;
    }

    .search_input {
        width: 145px !important;
        height: 30px !important;
    }

    .search_icon {
        position: absolute;
        right: 39px;
        top: 4px;
    }

    .search_inpu {
        width: 150px !important;
        height: 29px !important;
    }

    .qty_inpu {
        height: 29px !important;
    }

    .saveDraft-btn,
    .cancel-btn {
        font-size: 10px !important;
        width: 82px !important;
        height: 25px !important;
    }

    .total_a_sec label {
        font-size: 12px !important;
    }

    .classdate {
        font-size: 8px !important;
        color: var(--text-color) !important;
    }

    .classfont_size {
        font-size: 12px !important;
    }

    .update-class-container {
        justify-content: flex-start;
    }
}

@media (min-width: 1200px) and (max-width: 1365px) {

    .date-picker-style {
        width: 215px;
    }

    .search_input {
        width: 161px !important;
        height: 30px;
    }

    .update-class-container {
        justify-content: flex-start;
    }
}

@media (min-width: 768px) and (max-width: 899px) {
    .font14 {
        font-size: 12px !important;
    }
}

@media (min-width: 900px) and (max-width: 991px) {
    .font14 {
        font-size: 12px !important;
    }
}

@media (min-width :992px) {
    .search_icon {
        position: absolute;
        right: 14px;
        top: 3px;
    }

    .search_input {
        width: 145px !important;
        height: 30px !important;
    }
}

@media (min-width: 1214px) and (max-width: 1390px) {
    .date-picker-wrapper {
        width: 267px !important;
    }

    .fa-indian-rupee-sign {
        font-size: 10px;
    }
}

@media (min-width: 1440px) and (max-width: 2540px) {
    .update-class-container {
        justify-content: flex-start;
    }
}