.main-2 {
    background-color: rgb(235, 238, 255);
    height: 85vh;
    width: 27.7%;
    margin: 20px;
}

.main-package {
    position: relative;
    bottom: 20px;
    right: 10px;
}

.not-shiping,
.ship-pack,
.deli-pack {
    background-color: rgb(196, 255, 255);
    position: relative;
    right: 12px;
    width: 109%;
    height: 50px;
    padding: 10px;
}

.font-style {
    font-size: 14px;
}

.ship-pack {
    background-color: rgb(255, 248, 211);
}

.checkbox-package {
    margin-left: 10px;
}

.deli-pack {
    background-color: rgb(204, 255, 202);
}

.delete-package {
    margin-left: 10px;
    top: 2px;
    position: relative;
    cursor: pointer;
}

.delete-package:hover {
    color: #838383;
}

.icon {
    position: relative;
    left: 80%;
    bottom: 20px;
    display: flex;
    cursor: pointer;
}

.oid-id {
    padding-left: 4px;
}

.pack-colors{
    color: var(--color-text);
}

.package-name {
    margin-left: 12px;
    font-size: 18px;
}


.ship-pack::before,
.not-shiping::before {
    content: "";
    width: 0;
    height: 0;
    border-top: 27px solid transparent;
    border-bottom: 27px solid transparent;
    border-left: 20px solid rgb(255, 248, 211);
    position: absolute;
    left: 100%;
    top: -2px;
}

.not-shiping::before {
    border-left: 20px solid rgb(196, 255, 255);
}

.card-style {
    height: 17vh;
    width: 115%;
    position: relative;
    right: 10px;
    margin: 10px;
}

.packing {
    padding-left: 35px;
    position: relative;
    font-size: 14px;
}

.scrollbar-package {
    overflow-y: auto;
    overflow-x: hidden;
    height: 76vh;
    padding: 3px;
}

.order_Id {
    margin-top: 20px;
}

.no-data-found {
    text-align: center;
    margin-top: 35px;
    font-size: 14px;
}

.loader {
    height: 3px;
    width: 130px;
    --c: no-repeat linear-gradient(#eea300 0 0);
    background: var(--c), var(--c), #877e92;
    background-size: 60% 100%;
    animation: l16 3s infinite;
    margin-left: 70px;
    margin-top: 70%;
}

@keyframes l16 {
    0% {
        background-position: -150% 0, -150% 0
    }

    66% {
        background-position: 250% 0, -150% 0
    }

    100% {
        background-position: 250% 0, 250% 0
    }
}