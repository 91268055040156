.hover-table :hover {
    background-color: rgb(205, 201, 223);
}

.table-scrol {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 100px);
}

.text-colors-id {
    cursor: pointer;
    font-weight: bold;
}

.circle-button {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    background-color: var(--round-background-color);
    color: white;
    text-decoration: none;
}

.circle-button:hover {
    color: white;
}

.circle-button-disabled {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    background-color: rgb(107, 107, 107);
    color: white;
    text-decoration: none;
    z-index: 999;
}

.intent-delete {
    background-color: lightgrey;
    color: black;
    padding: 5px;
    border-radius: 25px;
    border: none;
    height: 35px;
    width: 90px;
    padding-top: 8px;
    font-size: 15px;
    cursor: pointer;
}

.table tbody tr:not(:first-child) {
    border-top: none;
}

.table-container1 table tbody tr:not(:first-child) {
    border-top: none !important;
}