/* Base Styles */
.employee-hr {
    border: 1px solid gray;
    width: 96%;
    margin-left: 22px;
    margin-top: -6px;
}

.popup-box-emp {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    top: 0;
    left: 0;
    background: rgb(0 0 0 / 6%);
}

.employee-list-scroll {
    max-height: calc(100vh - 180px);
    overflow-x: hidden;
    overflow-y: auto;
    cursor: pointer;
}

.card-employee {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5% auto;
    width: 80%;
    height: 45vh;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-emp {
    background-color: rgb(230, 235, 255);
    height: 17vh;
    width: 93%;
    border-radius: 3px;
    border: 1px solid #C0C2DE;
}

.emp-not-found {
    position: relative;
    left: 30%;
}

.image-emp {
    width: 85px;
    height: 85px;
    object-fit: cover;
    border-radius: 50%;
}

.ware-trash-2 {
    border-radius: 3px;
    cursor: pointer;
    font-size: 14px;
    height: 25px;
    width: 23px;
    color: var(--text-color-new);
    position: relative;
}

.emp-e-d {
    position: absolute;
    top: 220px;
    left: 100px;
    color: var(--text-color-new);

}

.emp-edit {
    border-radius: 3px;
    position: relative;
    // left: 1vh;
    cursor: pointer;
    font-size: 14px;
    width: 23px;
    color: var(--text-color-new);
}

.emp-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--text-color-new);

}

.grid-container-1 {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 2px;
}

.grid-item {
    display: flex;
    flex-direction: column;
}

.feature-line {
    width: 96%;
    margin-left: 10px;
}

.green-dot {
    border-radius: 50px;
    width: 12px;
    height: 12px;
    background: #7BAF34 0% 0% no-repeat padding-box;
    opacity: 1;
    margin: 5px 0px 7px 8px;
}

.emp-number-em {
    margin-right: 25px;
    font-size: 14px;
}

.email-fa{
    margin-top: 2px;
}
.phone-fa{
    margin-top: 4px;  
}
.emp-designation {
    font-size: 13px;
    color: var(--text-color-new);
    line-height: 1px;
    position: relative;
    bottom: 5px;
}

.emp-name {
    font-size: 14px;
    color: var(--text-color-new);
}

.emp-number {
    margin-bottom: 0.10rem;
}

.emp-number {
    margin-left: -80px;
    font-size: 14px;
    color: var(--text-color);
}

.hr-line-1 {
    position: relative;
    bottom: 17px;
    margin-left: 22px;
    width: 96%;
    color: var(--hr-line-color);
}

.pagination-container-emp {
    display: flex;
    justify-content: start;
    margin-top: 10px;
    position: fixed;
    bottom: 10px !important;
}

/* Media Queries */

/* For devices with a width between 320px and 600px */
@media (min-width: 320px) and (max-width: 600px) {
    .employee-hr {
        margin-left: 40px;
        width: 85%;
        margin-top: 20px;
    }

    .grid-container-1 {
        grid-template-columns: auto auto;
        grid-gap: 7px;
    }

    .padding {
        padding-left: 0px;
        padding-right: 0px;
    }

    .grid-item img {
        margin-left: -10px;
        height: 140px;
        border-radius: 50px;
    }
}

/* For devices with a width between 600px and 768px */
@media (min-width: 600px) and (max-width: 768px) {
    .employee-hr {
        margin-left: 20px;
        width: 75%;
    }

    .grid-container-1 {
        grid-template-columns: auto auto auto auto;
        grid-gap: 8px;
    }
}

/* For devices with a width between 768px and 992px */
@media (min-width: 768px) and (max-width: 1024px) {


    .emp-number {
        font-size: 13px;
        margin-left: -65px;


    }

    .card-employee {
        width: 50% !important;
        height: 100% !important;
    }

    .emp-number-em {
        font-size: 13px;
    }

    .emp-e-d {
        margin-left: -75px;
    }

}

/* For devices with a width between 992px and 1120px */
@media (min-width: 992px) and (max-width: 1120px) {
    .grid-container-1 {
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 8px;
        background-color: blue;
    }

    .grid-item img {
        width: 100px;
        height: 110px;
    }

    .emp-designation {
        font-size: 14px;
    }

    .emp-name {
        font-size: 12px;
    }

    .emp-number {
        font-size: 14px;
    }
  

    .green-dot {
        border-radius: 60px;
        width: 10px;
        height: 10px;
    }
}

@media (min-width: 1100px) and (max-width: 1213px) {
    .grid-container {
        grid-gap: 8px;
        grid-template-columns: repeat(4, 5fr);

    }

    .emp-number {
        font-size: 13px;
        margin-left: -65px;


    }

    .card-emp {
        height: 17vh;
    }

    .card-employee {
        width: 100%;

    }

    .emp-number-em {
        font-size: 13px;
    }

    .emp-e-d {
        margin-left: -75px;
    }

}

@media (min-width: 1024px) and (max-width: 1440px) {
    .emp-number {
        font-size: 13px;
        margin-left: -75px;
    }

    .card-employee {
        width: 100%;

    }

    .emp-number-em {
        font-size: 13px;
    }

    .emp-e-d {
        margin-left: -85px;
    }

    .card-emp {
        height: 18vh;
    }
}

@media (min-width: 1440px) and (max-width: 2540px) {
    .emp-number {
        font-size: 14px;
        margin-left: -75px;
    }

    .card-employee {
        width: 100%;
    }

    .emp-number-em {
        font-size: 14px;
    }

    .emp-e-d {
        margin-left: -85px;
        top: 73%;
    }
}