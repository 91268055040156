.emp_img {
    width: 130px;
    height: 21vh;
    border-radius: 60px;
    background-color: rgb(245, 243, 243);
    text-align: center;
}

.create-emp-scroll {
    height: calc(86vh - 60px);
    overflow-x: hidden;
    overflow-y: auto;
}

.emp_main {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 41vh;
    padding-top: 20px;
}

.emp_hr {
    position: relative;
    color: grey;
    bottom: 5px;
}

.contact-phone {
    font-size: 15px;
    color: var(--text-color);
}

.drop_hr {
    position: relative;
    color: gray;
    bottom: 15px;
}

.persnl_emp {
    position: relative;
    bottom: 15px;
}

.emp_body {
    position: relative;
    bottom: 15px;
}

.scroll-emp-create {
    height: calc(100vh - 433px) !important;
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 11px;
}

.emp_cancle {
    margin-left: 1px;
    padding: 5px;
    width: 99px;
    height: 34px;
    background-color: transparent;
    color: var(--text-color);
    border: 1px solid #727272;
    border-radius: 20px;
    cursor: pointer;
}

.emp_save {
    border: 0px solid #C0C2DE;
    border-radius: 20px;
    font-size: 14px;
    width: 99px;
    font-weight: 500;
    background-color: #C0C2DE;
    cursor: pointer;
    align-items: center;
    height: 34px;
    position: relative;
    right: 52px;
}

.emp-save-kyc {
    border: 0px solid #C0C2DE;
    border-radius: 20px;
    width: 99px;
    font-weight: 500;
    background-color: #C0C2DE;
    cursor: pointer;
    align-items: center;
    height: 34px;
    position: relative;
}

.emp_cancle-kyc {
    margin-left: 6px;
    padding: 5px;
    width: 99px;
    height: 34px;
    margin-top: 7%;
    background-color: transparent;
    color: var(--text-color);
    border: 1px solid #727272;
    border-radius: 20px;
    cursor: pointer;
    position: relative;
    bottom: 7px;
}


.emp-inpt {
    border: 1px solid #C0C2DE;
    border-radius: 5px;
    font-size: 14px;
    width: 100%;
    height: 38px;
    padding-left: 5px;
    background-color: transparent;
    color: var(--text-color);

}

.emp-inpt-passw {
    border: 1px solid #C0C2DE;
    border-radius: 5px;
    font-size: 14px;
    width: 100%;
    height: 38px;
    padding-left: 5px;
    background-color: transparent;
    color: var(--text-color);

}
.employee-theme{
    color: var(--text-color);
}

.emp_flex {
    display: flex;
    justify-content: space-between;
}

.group-section {
    background-color: transparent !important;
    font-size: 14px;
    font-weight: 400;
    border-radius: 3px;
    color: var(--text-color-new);
}

.group-section1 {
    background-color: transparent !important;
    font-size: 14px;
    font-weight: 400;
    border-radius: 3px;
    // color: var(--text-color-new);
}

.radio-emp {
    color: var(--text-color);
}

.emp_dropdown {
    display: flex;
    justify-content: start;
}

.adress_active {
    position: relative;
    bottom: 15px;
}

.emp_footer {
    display: flex;
    position: absolute;
    bottom: 0px
}

.password-input-container {
    display: flex;
    align-items: center;
}

.eye-icon {
    cursor: pointer;
    margin-left: -26px;
    height: 30px;
    width: 25px;
    z-index: 1;
    color: var(--text-color);
}

.employee-scroll {
    overflow: hidden;
    overflow-y: auto;
    max-height: 40vh;
}