.main-report {
  display: flex;
  height: 100vh;
}

.iframe-report {
  width: 1260px !important;
  height: 95vh !important;
}

.body-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iframe-report {
  width: 100%;
  height: 100%;
  border: none;
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .iframe-report {
    width: 100% !important;
    height: 100% !important;
  }
}
@media (min-width: 1440px) and (max-width: 2540px) {
  .iframe-report {
    width: 100% !important;
    height: 100% !important;
  }
}