.baap-commerce {
  font-size: 25px;
  color: var(--text-color);
  padding-left: 40px;
  padding-top: 15px;
}

.commerce-line {
  font-size: 14px;
  color: var(--text-color);
  padding-left: 40px;
}

.input-otp {
  padding-top: 150px;
  padding-left: 40px;
}

.enter-text {
  font-size: 14px;
  color: var(--text-color);
}

.otp-input {
  display: flex;
}

.padding50 {
  padding-left: 100px;
}

.note-name {
  color: var(--text-color);
  font-size: 14px;
  padding-top: 17px;
}

.input-text {
  padding-left: 64px;
}

.name-baap {
  color: var(--text-color);
  font-size: 17px;
}

.all-right {
  letter-spacing: 0px;
  color: var(--text-color);
  font-size: 10px;
}

.chevron-btn {
  width: 34px;
  height: 33px;
  border: 1px solid var(--unnamed-color-000000);
  background: #000000;
  border: 1px solid #000000;
  margin-left: 10px;
}

.resend-otp-text {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.otp-dot {
  padding-top: 2px;
  width: 32px;
  height: 34px;
  border: 1px solid #ccc;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-weight: bold;
  color: var(--text-color);
}

.password-dot {
  padding-top: 5px;
  width: 12.5rem;
  height: 30px;
  border: 1px solid #ccc;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-weight: bold;
  color: var(--text-color);
}

.otp {
  width: 34px;
  height: 33px;
  border: 1px solid var(--unnamed-color-000000);
  border: 1px solid var(--text-color);
  opacity: 1;
  background: transparent;
  outline: none;
}

.otp-inp {
  width: 270px;
  height: 33px;
  border: 1px solid var(--unnamed-color-000000);
  border: 1px solid var(--text-color);
  opacity: 1;
  background: transparent;
  outline: none;
}

.input-otp {
  padding-top: 11.5%;
  padding-left: 40px;
}

.password {
  padding-left: 3px;
  color: var(--text-color);
}

.padding-color {
  height: 100vh;
  background-color: var(--secondary-background-color);
}

.otp-dot[type="number"]::-webkit-inner-spin-button,
.otp-dot[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dot {
  font-weight: bolder;
}

.otp-password-text {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: blue;
}

.login-for-password {
  padding-left: 40px;
}