.bg_container {
    background-color: #e7e8fa;
}

.Select-main-group {
    background-color: #f0f1ff;
    height: 100vh;
}

.discription {
    font-size: 10px;
    line-height: 1px;
}

.select-input {
    padding: 10px 35px 10px 15px;
    border: 1px solid #ccc;
    border-radius: 20px;
    width: 320px;
    background-color: #f0f1ff;
    outline: none;
    margin-right: 7px;
}

.select-card {
    border: 0px !important;
    border-radius: 0 !important;
    width: 100% !important;
    background-color: #e7e8fa !important;
    min-height: 100px !important;
    box-shadow: none !important;
    margin-left: 6px !important;
}

.role-color {
    color: #5f629f !important;
}

.text-color {
    color: #5c5e7b !important;
    font-size: 14px;
    line-height: 10px;
}

.organization-color {
    font-size: 17px;
    font-weight: 900;
}

.organization-group-color {
    font-size: 20px !important;
    font-weight: 900;
}

.select-logo {
    height: 45px;
    margin-left: 10px;
}

.fixed-image {
    position: relative;
    height: 100px;
    width: 100px;
}

.group-image {
    max-width: 100%;
    padding: 10px;
}

.scroll {
    height: calc(100vh - 120px) !important;
    overflow-x: hidden;
    overflow-y: auto;
}

.group-select-card {
    background-color: #f0f1ff !important;
}

.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #7e7e7e;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loader {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 100px;
}

.loader div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: ripple 1.5s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.loader div:nth-child(2) {
    animation-delay: -0.75s;
}

@keyframes ripple {
    0% {
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        opacity: 1;
        transform: translate(-50%, -50%);
    }

    100% {
        top: 50%;
        left: 50%;
        width: 100px;
        height: 100px;
        opacity: 0;
        transform: translate(-50%, -50%);
    }
}