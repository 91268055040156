.term-item {
    display: flex;
    align-items: center;
    gap: 8px;  // Adjust gap as needed
    margin-bottom: 8px;
}

.term-content {
    flex: 1;
}

.delete-icon {
    margin-left: auto;
    cursor: pointer;
}
