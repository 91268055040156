.add-customerinfo-sidebar {
    top: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    position: fixed;
    width: 30%;
    background-color: var(--background-color) !important;
    color: var(--text-color) !important;
    overflow: auto;
    z-index: 1111;
}

.title-info {
    position: relative;
    right: 6px;
    top: 2px;
}

.react-datepicker__navigation--previous {
    padding-top: 30px;
}

.react-datepicker__navigation--next {
    padding-top: 30px;
}

.custom-date-picker {
    width: 272% !important;
    color: black;
}

.sr-hr-info {
    width: 104%;
    position: relative;
    right: 5px;
}

.react-datepicker__navigation-icon {
    top: 0px !important;
}

.cancel-info-btn {
    width: 100px;
    border: 1px solid #C0C2DE;
    border-radius: 22px;
    height: 28px;
    font-size: 14px;
    background-color: transparent;
    color: var(--text-color);
}

.width-datepicker {
    width: 230%;
}

@media (min-width: 1200px) and (max-width: 1365px) {
    .width-datepicker {
        width: 203%;

    }
}