.fa-angle-left {
    font-size: 18px;
}

.title-add {
    font-weight: 700;
    font-size: 18px;
    color: var(--text-color);
}

.product-scroll-line {
    height: calc(100vh - 50px);
    overflow-x: hidden;
    overflow-y: auto;
}

.taxes-title {
    margin-left: 4.5%;
    font-weight: 700;
    margin-top: 5%;
    color: var(--text-color);
}

.feature-payments {
    margin-top: 1%;
    margin-left: 1.8%;
    color: var(--text-color);
}

.carousel {
    width: 25%;
    color: var(--arrow-icon-next);
}

.rating {
    margin-top: 10%;
}

.ratings-input {
    margin-top: 3%;
    background: transparent;
    border: 1px solid black;
    width: 100%;
    padding: 12px;
}

.preview-image {
    height: 200px;
    width: auto;
}

.product-add-header {
    position: fixed;
    background-color: var(--secondary-background-color) !important;
    top: 0;
    z-index: 1;
    width: 100%;
}

.payment-check {
    cursor: pointer;
    color: var(--text-color);
}

.payment-mode-label {
    margin-left: 2%;
    margin-top: -20px;
}

.product-images {
    color: var(--text-color);
    font-size: 18px;
    opacity: 1;
}

.ml-3 {
    margin-left: 6rem !important;
}

.sub-line {
    font-size: 14px;
    margin-left: 2.5%;
    color: var(--text-color);
    font-weight: 500;
}

.select-category {
    margin-left: 8%;
    width: 86%;
    border: none;
    color: var(--text-color);
}

.select-category:focus {
    outline: none;
}

.image-select {
    display: none;
}

.product-preview {
    margin-left: 6%;
    font-size: 18px;
    color: var(--text-color);
}

.adds-button {
    background-color: #c0c2de;
    border: none;
    font-size: 14px;
    height: 35px;
    outline: none;
    color: var(--text-color);
}

.product-image {
    height: 110px;
    width: 100px;
    margin-left: 6%;
    border-radius: 6px;
    margin-top: 5%;
}

.subLine {
    font-size: 16px;
    margin-left: 6%;
    color: var(--text-color);
}

.text-price {
    text-decoration: line-through;
    margin-left: 4%;
}

.feature-option {
    color: black;
}

.feature-characteristics {
    margin-left: 4%;
    color: var(--text-color);
}

.character-cross {
    float: right;
}

.label {
    height: 110px;
    width: 100px;
    border: 1px dotted;
    margin-left: 3%;
}

.toastify-color-progress-light {
    background-color: black;
}

.upload-text {
    font-size: 12px;
    margin-left: 15%;
    margin-top: 35%;
    color: #909090;
}

.label {
    border: 1px solid #000 dashed;
    display: inline-block;
    padding: 10px;
    margin-bottom: 10px;
    height: 130px;
    width: 100px;
}

.image-sections {
    margin-top: 13%;
    margin-left: 2%;
}

.feature-remove {
    background: #CCCCDB 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border: none;
    width: 100%;
    color: var(--text-color);
}

.remove-button {
    display: block;
}

.multi-products {
    height: 130px;
    width: 90%;
    border-radius: 4px;
}

.feature-info {
    font-size: 12px;
}

.feature-inputs {
    width: 85%;
    font-size: 14px;
    padding-left: 3%;
    background: transparent;
    height: 35px;
    border: 1px solid #C0C2DE;
    border-radius: 5px;
    margin-left: 8%;
    color: var(--text-color);
}

.feature-inputs-pro {
    width: 38.3%;
    font-size: 14px;
    padding-left: 3%;
    background: transparent;
    height: 35px;
    border: 1px solid #C0C2DE;
    border-radius: 5px;
    margin-left: 8%;
    color: var(--text-color);
}

.sgst {
    height: 35px;
    font-size: 14px;
    padding-left: 2%;
    background: transparent;
    margin-left: 5%;
    width: 27%;
    margin-top: 2%;
    border: 1px solid #C0C2DE;
    border-radius: 5px;
    color: var(--text-color);
}

.feature-hsn-input {
    margin-left: 6.2%;
    font-size: 14px;
    border: 1px solid black;
    background: transparent;
    padding: 5px;
    width: 28.3%;
    padding-left: 3.5%;
}

.gst {
    height: 35px;
    font-size: 14px;
    padding-left: 2%;
    background: transparent;
    margin-left: 5%;
    width: 91%;
    border: 1px solid #C0C2DE;
    border-radius: 5px;
    color: var(--text-color);
}

.feature-input-select {
    width: 85%;
    height: 32px;
    border: 1px solid black;
    font-size: 14px;
    padding-left: 2%;
    background: transparent;
    margin-left: 8%;
    color: black;
}

.feature-property {
    padding-left: 1%;
    background-color: transparent;
    height: 35px;
    font-size: 14px;
    margin-left: 0.5%;
    border: 1px solid #C0C2DE;
    border-radius: 5px;
    color: var(--text-color)
}

.feature-value {
    margin-left: 1%;
    background-color: transparent;
    padding-left: 3%;
    width: 20%;
    height: 35px;
    font-size: 14px;
    border: 1px solid #C0C2DE;
    border-radius: 5px;
    color: var(--text-color);
}

.feature-description {
    margin-left: 1%;
    width: 33%;
    background-color: transparent;
    padding-left: 2%;
    height: 35px;
    font-size: 14px;
    border: 1px solid #C0C2DE;
    border-radius: 5px;
    color: var(--text-color);
}

.feature-add-property {
    background-color: #c0c2de;
    color: white;
    height: 33px;
    width: 13%;
    margin-left: 2%;
    border: 1px solid #C0C2DE;
    border-radius: 5px;
}

.feature-texts {
    font-size: 16px;
    margin-left: 8%;
    font-weight: 700;
    margin-top: 3%;
    color: var(--text-color);
}

.feature-text-1 {
    font-size: 16px;
    font-weight: 700;
    margin-top: 4%;
    color: var(--text-color);
}

.feature-input-1 {
    height: 35px;
    font-size: 14px;
    padding-left: 3%;
    background: transparent;
    width: 67%;
    border: 1px solid #C0C2DE;
    border-radius: 5px;
    color: var(--text-color);
    outline: none;
}

.feature-inputs-1 {
    height: 32px;
    border: 1px solid #000000;
    font-size: 14px;
    padding-left: 7%;
    background: transparent;
    width: 67%;
    color: black;
    margin-left: 1%;
}

select,
option :focus {
    outline: none;
}

.choTup {
    background: #DDEDC4;
    opacity: 1;
    font-weight: 500;
    color: var(--text-color-product);
}

.feature-character {
    margin-left: 3%;
    margin-top: 1%;
}

.feature-text-2 {
    font-size: 16px;
    color: #4E4343;
}

input {
    border: #000000;
}

.input-table {
    border: 1px solid black;
    background-color: transparent;
    padding: 5px;
}

.select-subcategory {
    width: 37%;
    margin-top: 7%;
    padding: 1x;
    color: var(--text-color);
}

.product-info {
    font-size: 16px;
    color: var(--text-color);
}

.image-group {
    display: flex;
    margin-left: 2%;
}

.image-product {
    height: 150px;
    width: 120px;
}

.image-section {
    margin-top: 4%;
    margin-left: 3%;
    display: flex;
    color: var(--text-color);
}

.features-inputs {
    margin-left: 4%;
    width: 80%;
    height: 32px;
    border: 1px solid #000000;
    font-size: 14px;
    padding-left: 2%;
    background: transparent;
    margin-top: 3%;
    color: black;
}

.add-character {
    margin-left: 4%;
    width: 83%;
    height: 35px;
    font-size: 14px;
    padding-left: 2%;
    background: transparent;
    margin-top: 3%;
    border: 1px solid #C0C2DE;
    border-radius: 5px;
    color: var(--text-color);
}

.feature-cross-icon {
    float: right;
}

.feature-input-3 {
    margin-left: 3%;
    border: 1px solid #000000;
    font-size: 14px;
    padding-left: 5%;
    background: transparent;
    margin-top: 3%;
    height: 32px;
    width: 70%;
    color: black;
}

.feature-input-4 {
    margin-left: 6%;
    border: 1px solid #000000;
    font-size: 14px;
    padding-left: 3%;
    background: transparent;
    height: 32px;
    width: 40%;
    color: black;
}

.feature-input-5 {
    margin-left: 10%;
    border: 1px solid #000000;
    font-size: 14px;
    padding-left: 3%;
    background: transparent;
    height: 32px;
    color: black;
}

.feature-prices {
    border: 1px solid #000000;
    padding: 5px;
    background: transparent;
    width: 40%;
}

.feature-input-6 {
    border: 1px solid #000000;
    font-size: 14px;
    padding-left: 5%;
    background: transparent;
    height: 32px;
    width: 100%;
    color: black;
}

.feature-input-7 {
    margin-left: 1%;
    border: 1px solid #000000;
    font-size: 14px;
    padding-left: 13%;
    background: transparent;
    height: 32px;
    width: 100%;
    color: black;
}

.input-error {
    border: 1px solid red;
}

.ql-container {
    box-sizing: border-box;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 13px;
    height: 100%;
    margin: 0px;
    position: relative;
    overflow-y: auto;
}

.feature-variant {
    margin-left: 19px;
    margin-top: 2%;
    font-size: 16px;
    color: var(--text-color);
}

.payment-text {
    color: var(--text-color);
}

.feature-add-button {
    background: var(--unnamed-color-000000) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-000000);
    background: #000000 0% 0% no-repeat padding-box;
    border: 1px solid #000000;
    opacity: 1;
    width: 84%;
    height: 32px;
    color: white;
    font-size: 14px;
    margin-left: 45%;
}

.feature-select {
    padding: 6px;
    border: 1px solid #C0C2DE;
    background-color: transparent;
    width: 90%;
}

.feature-value-input {
    border: 1px solid #000000;
    padding: 3px;
    margin-left: 2%;
    background-color: transparent;
    padding-left: 3%;
}

.feature-save-button {
    width: 22%;
    font-size: 16px;
    margin-left: 22px;
    border-radius: 5px;
    background-color: #C0C2DE;
    border: none;
    height: 35px;
    outline: none;
}

.feature-cancel-button {
    height: 35px;
    font-size: 14px;
    width: 22%;
    color: black;
    margin-left: 3%;
    font-weight: 700;
    background-color: transparent;
    border: 1px solid #C0C2DE;
    opacity: 1;
    border-radius: 5px;
}

.table-data {
    font-size: 12px;
    margin-left: 3%;
}

td {
    color: var(--text-color) !important;
}

.feature-line {
    font-size: 14px;
    color: var(--text-color);
    opacity: 0.5;
}

.arrow-icon {
    font-size: 21px;
    margin-top: 2px;
    color: var(--text-color);
}

.cancels {
    color: var(--text-color);

}

.description-product {
    color: var(--text-color);
}

.product-lable {
    padding-left: 29px;
    font-size: 15px;
    color: var(--text-color);

}

.product-price {
    font-size: 15px;
    color: var(--text-color);
}

.validation-errors {
    color: red;
    font-size: 14px;
    padding-left: 5px;
}
.validation-error {
    color: red;
    font-size: 14px;
}

@media (min-width:769px) and (max-width:1024px) {

    .quill {
        width: 100%;
    }

    .image-section {
        width: 91%;
    }

    .feature-add-button {
        width: 100%;
    }

    .feature-input-5 {
        width: 103%;
    }

    .feature-inputs-1 {
        margin-top: 15%;
    }

    .feature-input-6 {
        width: 90%;
    }

    .table {
        width: auto;
    }

    .label {
        height: 50%;
    }

    th {
        color: var(--text-color);
        font-size: 14px;
    }

    .product-add-header {
        width: 100%;
    }

    .sub-line {
        width: 90%;
        margin-left: 4%;
    }

    .feature-input-1 {
        width: 85%;
    }

    .feature-add-button {
        width: 100%;
    }

    .choTup {
        margin-top: 6%;
    }
}

@media (min-width:320px) and (max-width:720px) {
    .product-add-header {
        width: 58%;
    }

    .image-section {
        width: 95%;
    }

    .multi-products {
        height: 75%;
    }

    .label {
        height: 70%;
    }

    .feature-text-1 {
        margin-top: 8%;
    }

    .feature-texts {
        margin-top: 5%;
    }

    .feature-text-1 {
        margin-left: 8%;
    }

    .feature-input-1 {
        margin-left: 12%;
        width: 80%;
    }

    .quill {
        width: 89%;
        margin-left: 8%;
    }

    .feature-inputs {
        width: 88%;
    }

    .feature-text-1 {
        margin-left: 12%;
    }

    .sub-category {
        width: 79%;
    }

    .choTup {
        margin-left: 12%;
    }

    .adds-button {
        width: 92%;
        margin-top: 5%;
        margin-left: 12%;
    }

    .feature-character {
        width: 100%;
    }

    .feature-text-2 {
        font-size: 8px;
        width: 100%;
    }

    .feature-variant {
        margin-top: 5%;
    }

    .add-character {
        margin-left: 12%;
        width: 92%;
    }

    .feature-input-4 {
        width: 80%;
        margin-left: 12%;
    }

    .feature-input-5 {
        margin-top: 3%;
        margin-left: 12%;
        width: 80%;
    }

    .feature-input-6 {
        width: 80%;
        margin-left: 12%;
        margin-top: 3%;
    }

    .feature-input-7 {
        width: 80%;
        margin-left: 12%;
        margin-top: 3%;
    }

    .feature-add-button {
        margin: 0;
        margin-left: 12%;
        margin-top: 3%;
        width: 80%;
    }

    .saves {
        width: 80%;
        margin-left: 12%;
    }

    .cancels {
        margin-top: 3%;
        margin-left: 12%;
        width: 80%;
    }

    .feature-line {
        font-size: 10px;
    }
}

@media(min-width:592px)and (max-width:768px) {
    .product-preview {
        font-size: 10px;
    }

    .feature-value-input {
        width: 100%;
    }

    .feature-prices {
        width: 100%;
    }

    .feature-add-button {
        width: 100%;
        font-size: 12px;
    }

    .product-add-header {
        width: 100%;
    }

    .title-add {
        font-size: 12px;
    }

    .fa-angle-left {
        font-size: 12px;
    }

    .image-sections {
        margin-top: 60%;
    }

    .label {
        height: 30%;
        width: 15%;
    }

    .product-image {
        height: 100%;
        width: 90%;
    }

    .subLine {
        font-size: 8px;
    }

    .star {
        font-size: 10px;
    }

    .feature-texts {
        font-size: 9px;
    }

    .feature-text-1 {
        font-size: 12px;
    }

    .sub-line {
        font-size: 10px;
        width: 90%;
    }

    .images {
        height: 70px;
        width: auto;
    }

    .label {
        height: 30%;
        width: 56%;
    }

    .multi-products {
        height: 72%;
        width: 80%;
    }

    .feature-inputs {
        width: 80%;
    }

    .feature-texts {
        font-size: 12px;
    }

    .sub-category {
        width: 50%;
        margin-left: 27%;
    }

    .choTup {
        margin-left: 27%;
    }

    .feature-input-4 {
        margin-left: 8%;
    }

    .feature-input-5 {
        width: 100%;
    }

    .feature-input-6 {
        margin: 0;
        width: 100%;
    }

    .saves {
        margin-left: 7%;
        width: 50%;
    }
}

@media(min-width:900px)and (max-width:1100px) {
    .feature-save-button {
        margin-left: 4%;
        width: 32%;
    }

    .sub-line {
        font-size: 16px;
    }

    .sub-category {
        margin-top: 15%;
        width: auto;
    }

    .product-preview {
        margin-left: 8%;
    }
}

@media (max-width: 991.98px) {
    .product-info {
        position: absolute;
        left: 0%;
    }

    .features-inputs {
        position: relative;
        right: 2%;
    }

    .variants-section {
        position: relative;
        right: 2%;
    }

    .feature-save-button-responsive {
        position: relative;
        right: 2%;
    }

    .adds-button {
        position: relative;
        right: 3%;
    }

    .feature-line {
        position: relative;
        right: 3%
    }

    .feature-inputs {
        font-size: 11px;
    }

    .features-inputs {
        font-size: 11px;
    }

    .feature-input-1 {
        font-size: 11px;
    }

    .feature-property {
        font-size: 11px;
    }

    .title-add {
        font-size: 17px;
        position: relative;
        right: 10%;
    }

    .product-preview {
        font-size: 12px;
    }
}

::-webkit-scrollbar {
    width: 5px;
    background-color: rgb(202, 201, 201);
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
}