.feature-sidebar {
    top: 0%;
    right: 0;
    bottom: 0;
    height: 100vh;
    z-index: 999;
    position: fixed;
    width: 33%;
    background-color: var(--background-color);
    overflow: hidden;
    padding-top: 2%;
}

.tooltip-content {
    max-width: 300px;
}

.read-more-btn {
    display: block;
    margin-top: 5px;
    color: blue;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    text-decoration: underline;
}

.read-more-btn:hover {
    text-decoration: none;
}

.category-title[data-tooltip-id] {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.category-edit {
    cursor: pointer;
    color: var(--text-color);
}

.subcategory-checkbox {
    margin-left: 3%;
}

.subcategory-checkbox- {
    margin-left: 28px;
}

.subcategory-checkbox-a {
    margin-left: 12px;
}

.subcategory-checkbox-c {
    margin-left: 2px;
}

.scrollbar-category {
    height: calc(100vh - 170px);
    overflow-x: hidden;
    overflow-y: auto;
}

.required {
    color: red;
    font-size: 22px;
    margin-left: 1%;
}

.scrollbar-right {
    margin-right: 70%;
}

.category-button {
    margin-left: 4%;
}

.category-list-scroll {
    height: 69vh;
    overflow-x: hidden;
    overflow-y: auto;
    cursor: pointer;
}

.searching-category {
    position: absolute;
    top: 33px;
    transform: translatex(-50%);
    padding-left: 3%;
    font-size: 15px;
}

.error-messages {
    margin-left: 6%;
    color: red;
}

.category-up-btn {
    position: relative;
    bottom: 5px;
}

.feature-title {
    margin-left: 7%;
    font-weight: 700;
    padding-top: 3%;
}

.feature-titles {
    margin-left: 7%;
    font-weight: 700;
    color: var(--text-color);
}

.he-line {
    border: 1px solid #D9D8D8;
    margin-left: 0.5%;
    width: 97%;
    color: var(--hr-line-color);
}

.category-section {
    margin-left: 4%;
}

.upload-input {
    display: none;
}

.text-area:focus {
    outline: none;
}

.category-inputs {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #C0C2DE;
    width: 90%;
    font-size: 14px;
    background: transparent;
    margin-left: 16px;
    color: var(--text-color);
}

.feature-typeahead {
    margin-left: 3%;
}

.prod-input-select {
    border: 1px solid var(--unnamed-color-000000);
    border: 1px solid #000000;
    opacity: 1;
    background: transparent;
    width: 90%;
    height: 30px;
    font-size: 14px;
    padding-left: 3%;
    font-size: 14px;
    margin-left: 3%;
}

.upload-label {
    display: block;
    width: 90px;
    height: 100px;
    cursor: pointer;
}

.feature-cancel {
    color: var(--text-color);
    height: 34px;
    border: 1px solid #C0C2DE;
    background: transparent;
    border-radius: 22px;
    width: 25%;
    margin-top: 12px;
}

.upload-area {
    border: 2px dashed #ccc;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
}

.image-upload {
    height: 100px;
    width: 100px;
    border: 1px dotted;
    margin-left: 24%;
}

.dashed-box {
    font-size: 16px;
    color: #999;
}

.subcategory-input {
    border: 1px solid var(--unnamed-color-000000);
    border: 1px solid #000000;
    opacity: 1;
    background: transparent;
    height: 32px;
    width: 74%;
    font-size: 14px;
    margin-left: 3%;
}

.subcategory-inputs {
    border: 1px solid var(--unnamed-color-000000);
    border: 1px solid #000000;
    opacity: 1;
    background: transparent;
    height: 35px;
    width: 75%;
    font-size: 14px;
    margin-left: 3.5%;
}

.h1 {
    font-size: 16px;
}

.upload-button {
    background-color: transparent;
    border: 1px solid black;
    margin-left: 32%;
    border-radius: 4px;
    font-size: 15px;
    margin-top: 20%;
}

.select-file {
    display: none;
}

.error-message {
    margin-left: 3%;
    color: red;
}

.error-message {
    margin-left: 3%;
    color: rgb(202, 48, 48);
}

.mandatory-indicator::after {
    content: '*';
    color: red;
}

.features-saves-btn {
    border: none;
    background: #C0C2DE;
    color: black;
    height: 34px;
    border-radius: 18px;
    width: 25%;
    margin-left: 3%;
    font-size: 14px;
}

.feature-sidebar.open {
    right: 0;
}

.close-icons {
    position: absolute;
    top: 42px;
    right: 39px;
    cursor: pointer;
    font-size: 20px;
}

.close-icon-category {
    position: absolute;
    top: 17px;
    right: 40px;
    cursor: pointer;
    font-size: 20px;
    color: var(--text-color);
}

.left-side {
    float: left;
    width: 6%;
}

.right-side {
    width: 94%;
    float: right;
}

.feature-category-pages {
    margin-left: 1%;
}

.input-label {
    margin-left: 3%;
    font-weight: 700;
    color: var(--text-color);
}

.input-labels {
    margin-left: 3.7%;
    font-weight: 700;
    color: var(--text-color);
}

.category-select-true {
    color: var(--text-color);
}

.add-category-hr {
    margin-top: 30px;
    margin-bottom: 1rem;
    margin-left: 7.1%;
    width: 84%;
    color: var(--hr-line-color);
}

.update-category-hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 7%;
    width: 86%;
}

.subcategory-input-add {
    border: 1px solid var(--unnamed-color-000000);
    border: 1px solid #000000;
    opacity: 1;
    background: transparent;
    height: 32px;
    width: 89%;
    font-size: 14px;
    margin-left: 3%;
}

.tool-text {
    height: 40px;
}

@media (min-width:768px)and (max-width:1024px) {
    .feature-title {
        font-size: 18px;
    }

    .update-category-hr {
        width: 83%;
    }

    .close-icons {
        font-size: 16px;
        right: 22px;
        top: 22px;
    }

    .feature-title {
        margin-left: 10%;
        padding-top: 10%;
    }

    .close-icon-category {
        right: 30px;
    }

    .input-labels {
        margin-left: 6%;
        font-weight: 600;
    }

    .subcategory-input {
        border: 1px solid var(--unnamed-color-000000);
        border: 1px solid #000000;
        opacity: 1;
        background: transparent;
        height: 32px;
        width: 64%;
        font-size: 14px;
        margin-left: 6%;
    }

    .subcategory-input-add {
        border: 1px solid var(--unnamed-color-000000);
        border: 1px solid #000000;
        opacity: 1;
        background: transparent;
        height: 32px;
        width: 64%;
        font-size: 14px;
    }

    .subcategory-inputs {
        border: 1px solid var(--unnamed-color-000000);
        border: 1px solid #000000;
        opacity: 1;
        background: transparent;
        height: 32px;
        width: 64%;
        font-size: 14px;
        margin-left: 6%;
    }

    .subcategory-add-button {
        width: 22%;
    }

    .feature-cancel {
        width: 28%;
    }
}